export const Symbols = {
    stores: {
        me: Symbol.for('me'),
        book: Symbol.for('book'),
        bookList: Symbol.for('bookList'),
        bookmark: Symbol.for('bookmark'),
        content: Symbol.for('content'),
        contentList: Symbol.for('contentList'),
        device: Symbol.for('device'),
        deviceList: Symbol.for('deviceList'),
        googleDrive: Symbol.for('googleDrive'),
        googleBooksList: Symbol.for('googleBooksList'),
        googleBooks: Symbol.for('googleBooks'),
        highlight: Symbol.for('highlight'),
        highlightList: Symbol.for('highlightList'),
        status: Symbol.for('status')
    }
}