import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { View } from './view'
import { Symbols, inject } from '../di'
import { MeStore } from '../stores'

@customElement('kob-login')
export class LoginView extends View {

    @inject(Symbols.stores.me)
    meStore: MeStore

    handleLogin = () => this.meStore.startOauth()

    async connectedCallback() {
        await window.customElements.whenDefined('kob-google-account')
        //@ts-ignore
        window.google?.accounts.id.disableAutoSelect()
        super.connectedCallback()
    }

    //https://github.com/BurakGur/google-one-tap/blob/master/index.js
    render() {
        return html`
        <!--
            <div id="overlayer"></div>
            <div class="loader">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </div>
        -->
        <div class="site-wrap">
        
            <div class="site-mobile-menu site-navbar-target">
                <div class="site-mobile-menu-header">
                    <div class="site-mobile-menu-close mt-3">
                        <span class="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div class="site-mobile-menu-body"></div>
            </div>
        
        
            <header class="site-navbar js-sticky-header site-navbar-target" role="banner">
        
                <div class="container">
                    <div class="row align-items-center">
        
                        <div class="col-6 col-xl-2">
                            <div class="mb-0 site-logo">
                                <svg width="409.8846153846154" height="50" viewBox="0 0 409.8846153846154 113"
                                    class="css-1j8o68f">
                                    <a href="#home-section">
                                        <defs id="SvgjsDefs4122">
                                            <linearGradient id="SvgjsLinearGradient4129">
                                                <stop id="SvgjsStop4130" stop-color="#f96813" offset="0"></stop>
                                                <stop id="SvgjsStop4131" stop-color="#fbb040" offset="1"></stop>
                                            </linearGradient>
                                        </defs>
                                        <g id="SvgjsG4123" featurekey="symbolGroupContainer" transform="matrix(1,0,0,1,0,0)"
                                            fill="url(#SvgjsLinearGradient4129)">
                                            <rect xmlns="http://www.w3.org/2000/svg" width="87" height="113" rx="10" ry="10">
                                            </rect>
                                        </g>
                                        <g id="SvgjsG4124" featurekey="symbolFeature-0"
                                            transform="matrix(0.8742000937274531,0,0,0.8742000937274531,1.5958243588866985,14.493070311063224)"
                                            fill="#0c0c0c">
                                            <g xmlns="http://www.w3.org/2000/svg" fill="none" stroke="black"
                                                transform="scale(1 -1)">
                                                <g transform="translate(0 -96)">
                                                    <g>
                                                        <path
                                                            d="M 21.633,34.925 C 24.973,46.855 40.837,53.71 46.922,66.223 C 49.866,72.279 48.49,83.461 43.464,87.939 C 43.197,88.178 42.09,90.326 43.217,89.521 C 54.416,81.327 63.521,70.509 59.309,60.068 C 53.464,45.577 38.409,38.34 38.519,23.585 C 38.566,17.447 43.088,12.523 48.838,7.375 L 49.073,6.299 C 34.143,10.56 17.742,21.028 21.633,34.925 Z M 59.314,18.723 C 67.987,26.524 73.282,31.263 71.043,40.298 C 73.766,36.119 76.295,30.323 74.135,25.806 C 72.203,21.769 70.28,18.956 63.244,15.02 C 59.515,12.935 56.712,10.677 54.37,6.898 C 54.248,10.853 55.011,14.852 59.314,18.723 Z M 49.686,32.153 C 53.003,38.255 57.845,42.622 62.538,48.882 C 65.869,53.325 68.92,60.704 65.731,66.728 L 65.937,67.016 C 70.824,61.556 71.028,52.654 69.495,46.059 C 67.086,35.69 58.5,31.5 53.511,23.918 C 51.557,20.948 50.446,17.311 51,13.5 C 45.461,18.021 46.717,26.69 49.686,32.153 Z"
                                                            stroke-linejoin="miter" stroke-linecap="round" stroke="none"
                                                            stroke-width="0.75" fill="#0c0c0c" marker-start="none"
                                                            marker-end="none" stroke-miterlimit="79.8403193612775">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="SvgjsG4125" featurekey="nameFeature-0"
                                            transform="matrix(1.7787445240541133,0,0,1.7787445240541133,98.46202679344314,3.9153477813703716)">
                                            <path
                                                d="M14.4 32 l-5.24 8 l-4.36 0 l0 -20.72 l4.36 0 l0 13.28 l9.56 -13.28 l5.28 0 l-6.84 9.32 l3.04 5.12 q0.84 1.16 1.8 1.56 q0.68 0.28 1.68 0.28 l1.44 0 l0 4.44 l-1.8 0 q-1.24 0 -2 -0.2 q-0.96 -0.24 -1.88 -0.88 q-1 -0.76 -2.16 -2.2 z M50.88 29.48 q0 -2.2 -1.2 -3.8 q-1.04 -1.4 -2.92 -2.16 q-1.72 -0.68 -3.8 -0.68 t-3.84 0.72 q-1.88 0.76 -2.92 2.24 q-1.16 1.6 -1.16 3.84 t1.2 3.84 q1.04 1.44 2.92 2.24 q1.76 0.72 3.8 0.72 t3.84 -0.8 q1.84 -0.84 2.92 -2.36 q1.16 -1.64 1.16 -3.8 z M55.2 29.439999999999998 q0 3.4 -1.8 6.04 q-1.64 2.44 -4.52 3.84 q-2.76 1.32 -5.96 1.32 t-5.96 -1.32 q-2.84 -1.36 -4.48 -3.76 q-1.76 -2.64 -1.76 -6 t1.8 -5.96 q1.64 -2.4 4.52 -3.72 q2.76 -1.28 5.96 -1.28 t5.92 1.2 q2.84 1.28 4.48 3.64 q1.8 2.56 1.8 6 z M66.92 23.4 l0 4.72 q1.24 -0.72 3 -1 q1.36 -0.2 3.48 -0.2 l2.12 0 q1.08 0 1.58 -0.44 t0.5 -1.32 t-0.56 -1.32 t-1.52 -0.44 l-8.6 0 z M66.92 32.32 l0 3.52 l8.6 0 q1.48 0 2.24 -0.44 q1 -0.6 1 -1.96 q0 -1.16 -0.78 -1.74 t-2.46 -0.58 l-2.12 0 q-2.76 0 -4 0.24 q-1.08 0.2 -2.48 0.96 z M82.88 33.519999999999996 q0 3.04 -1.96 4.76 t-5.4 1.72 l-12.72 0 l0 -20.72 l12.72 0 q1.68 0 3.12 0.74 t2.3 2.08 t0.86 3.02 q0 2.32 -1.36 3.56 q1.16 0.68 1.8 1.98 t0.64 2.86 z M106.16 40 l-15.28 0 l0 -20.72 l4.28 0 l0 16.56 l11 0 l0 4.16 z M116.24 40 l-4.08 0 l0 -20.72 l4.08 0 l0 20.72 z M151 19.28 l-2.04 3.32 l0 17.4 l-4.52 0 l0 -12.12 l-7.32 9.76 l-7.32 -9.76 l0 12.12 l-4.52 0 l0 -17.4 l-2.04 -3.32 l5.04 0 l8.84 11.72 l8.84 -11.72 l5.04 0 z M162.07999999999998 32.56 l0 3.28 l13 0 l0 4.16 l-17.08 0 l0 -20.72 l16.32 0 l0 4.12 l-12.24 0 l0 5.04 q1.24 -0.72 3 -1 q1.36 -0.2 3.48 -0.2 l3.04 0 l0 4.12 l-3.04 0 q-2.76 0 -4 0.24 q-1.08 0.2 -2.48 0.96 z">
                                            </path>
                                        </g>
                                    </a>
                                </svg>
                            </div>
                        </div>
        
                        <div class="col-12 col-md-10 d-none d-xl-block">
                            <nav class="site-navigation position-relative text-right" role="navigation">
        
                                <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                    <li><a href="#features-section" class="nav-link">Features</a></li>
                                    <li><a href="#pricing-section" class="nav-link">Pricing</a></li>
                                    <li><a href="https://docs.kobli.me" target="_blank" class="nav-link">Documentation</a></li>
                                    <li><a href="https://koblime.canny.io/general" target="_blank" class="nav-link">Requests</a></li>
                                    <li class="sign-in">
                                        <div class="g_id_signin"
                                            data-type="standard"
                                            data-size="large"
                                            data-theme="outline"
                                            data-text="sign_in_with" data-shape="rectangular" data-logo_alignment="right">
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
        
        
                        <div class="col-6 d-inline-block d-xl-none ml-md-0 py-3" style="position: relative; top: 3px;"><a
                                href="#" class="site-menu-toggle js-menu-toggle float-right"><span
                                    class="icon-menu h3 text-black"></span></a>
                        </div>
        
                    </div>
                </div>
        
            </header>
        
            <div class="site-section hero" id="home-section">
                <div class="container text-center">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-10">
                            <div class="mb-5">
                                <h1 class="hero-heading">Wireless sync for <strong>kobo</strong></h1>
                                <p>Backup highlights, bookmarks and reading progress for your side-loaded books</p>
                                <p>Koblime is currently in <strong>beta</strong></p>
                                <a href="#" @click=${this.handleLogin} class="btn btn-primary">Get Started</a>
                            </div>
                            <img src="assets/images/koblime-laptop.png" alt="image" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="site-section bg-light pt-0" id="features-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center mb-5">
                            <span class="subtitle-1">Features</span>
                            <h2 class="section-title-1 font-weight-bold">Easy and Private</h2>
                        </div>
                    </div>
                    <div class="row align-items-center mb-5">
                        <div class="col-lg-6 mb-5 order-lg-2 mb-lg-0" data-aos="fade-right">
                            <img src="assets/images/koblime-main.png" alt="Image"
                                class="img-fluid img-shadow">
                        </div>
                        <div class="col-lg-5 mr-auto">
                            <div class="mb-4">
                                <h2 class="section-title-2">Wireless Sync</h2>
                                <p>Finally! Sync read progress and highlights from all your devices
                                     without messing with complex scripts or wires.</p>
                            </div>
        
                            <div class="d-flex feature-v1">
                                <span class="wrap-icon icon-tablet"></span>
                                <div>
                                    <h3>Multi-device Support</h3>
                                    <p>Individually backs up each Kobo device.</p>
                                </div>
                            </div>
        
                            <div class="d-flex feature-v1">
                                <span class="wrap-icon icon-book"></span>
                                <div>
                                    <h3>Side-loaded books</h3>
                                    <p>Backs up reading progress and highlights from side-loaded books.</p>
                                </div>
                            </div>
        
                        </div>
                    </div>
        
                    <div class="row align-items-center">
                        <div class="col-lg-6 mb-5 mb-lg-0" data-aos="fade-left">
                            <img src="assets/images/koblime-download.png" alt="Image"
                                class="img-fluid img-shadow">
                        </div>
                        <div class="col-lg-5 order-lg-1 ml-auto">
                            <div class="mb-4">
                                <h2 class="section-title-2">One-click Setup</h2>
                                <p>Made for non-technical users, we have made it really easy to get started.  No hacking skills required.
                                </p>
                            </div>
        
                            <div class="d-flex feature-v1">
                                <span class="wrap-icon icon-bed"></span>
                                <div>
                                    <h3>Easy Install</h3>
                                    <p>Install the Koblime app the same way you upgrade your Kobo.</p>
                                </div>
                            </div>
        
                            <div class="d-flex feature-v1">
                                <span class="wrap-icon icon-lock"></span>
                                <div>
                                    <h3>Private and Secure</h3>
                                    <p>You do not have to upload the kobo database and our wireless sync app only reads book content, highlight and progress information.
                                    Your kobo credentials and other information never leave the device.
                                    </p>
                                </div>
                            </div>
        
                        </div>
                    </div>
        
                    <!-- div class="row align-items-center mb-5">
                        <div class="col-lg-6 mb-5 order-lg-2 mb-lg-0" data-aos="fade-right">
                            <img src="assets/images/untree.co_dashboard_mocklayout.jpg" alt="Image"
                                class="img-fluid img-shadow">
                        </div>
                        <div class="col-lg-5 mr-auto">
                            <div class="mb-4">
                                <h2 class="section-title-2">Transfer books from Google Drive</h2>
                                <p>Keep your books on Google Drive and have them transfer to your kobo over the air whenever you need</p>
                            </div>
        
                            <div class="d-flex feature-v1">
                                <span class="wrap-icon icon-users"></span>
                                <div>
                                    <h3>Infinite space, no tools required</h3>
                                    <p>Running out of space on your device? Keep your books on Google Drive and only
                                        transfer what you are actively reading to the device.</p>
                                </div>
                            </div>
        
                            <div class="d-flex feature-v1">
                                <span class="wrap-icon icon-layers"></span>
                                <div>
                                    <h3>Free up space with impunity!</h3>
                                    <p>Delete books on your device to free up space knowing full well that you can easily restore
                                         the book, highlights and reading progress whenever you would like.</p>
                                </div>
                            </div>
        
                        </div>
                    </div -->
        
                </div>
            </div>
        
            <!-- div class="site-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 text-center mb-5 mx-auto">
                            <span class="subtitle-1">More Features</span>
                            <h2 class="section-title-1 font-weight-bold">What People Says</h2>
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="0">
                            <div class="feature-v2">
                                <span class="icon-people_outline"></span>
                                <h3>User Collaboration</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="100">
                            <div class="feature-v2">
                                <span class="icon-phone_android"></span>
                                <h3>Mobile Integration</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200">
                            <div class="feature-v2">
                                <span class="icon-pie_chart"></span>
                                <h3>Smart Analytics</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300">
                            <div class="feature-v2">
                                <span class="icon-public"></span>
                                <h3>Geolocation</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
        
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="0">
                            <div class="feature-v2">
                                <span class=" icon-search2"></span>
                                <h3>Easy to find</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="100">
                            <div class="feature-v2">
                                <span class=" icon-security"></span>
                                <h3>Good Security</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200">
                            <div class="feature-v2">
                                <span class="icon-visibility"></span>
                                <h3>Aesthetic</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300">
                            <div class="feature-v2">
                                <span class="icon-settings"></span>
                                <h3>Dashboard</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem saepe aut, vel. Nemo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div -->
        
            <!-- div class="site-section bg-light testimonial-wrap" id="testimonials-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center mb-5">
                            <span class="subtitle-1">Testimonials</span>
                            <h2 class="section-title-1 font-weight-bold">What People Says</h2>
                        </div>
                    </div>
                </div>
                <div class="slide-one-item home-slider owl-carousel">
                    <div>
                        <div class="testimonial">
        
                            <blockquote class="mb-5">
                                <p>&ldquo;Far far away, behind the word mountains, far from the countries Vokalia and
                                    Consonantia, there
                                    live the blind texts. Separated they live in Bookmarksgrove right at the coast of the
                                    Semantics, a large
                                    language ocean.&rdquo;</p>
                            </blockquote>
        
                            <figure class="mb-4 d-flex align-items-center justify-content-center">
                                <div><img src="assets/images/person_3.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
                                <p>John Smith</p>
                            </figure>
                        </div>
                    </div>
                    <div>
                        <div class="testimonial">
        
                            <blockquote class="mb-5">
                                <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary
                                    regelialia. It
                                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;
                                </p>
                            </blockquote>
                            <figure class="mb-4 d-flex align-items-center justify-content-center">
                                <div><img src="assets/images/person_2.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
                                <p>Christine Aguilar</p>
                            </figure>
        
                        </div>
                    </div>
        
                    <div>
                        <div class="testimonial">
        
                            <blockquote class="mb-5">
                                <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum
                                    decided to
                                    leave for the far World of Grammar.&rdquo;</p>
                            </blockquote>
                            <figure class="mb-4 d-flex align-items-center justify-content-center">
                                <div><img src="assets/images/person_4.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
                                <p>Robert Spears</p>
                            </figure>
        
        
                        </div>
                    </div>
        
                    <div>
                        <div class="testimonial">
        
                            <blockquote class="mb-5">
                                <p>&ldquo;The Big Oxmox advised her not to do so, because there were thousands of bad Commas,
                                    wild
                                    Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her
                                    seven
                                    versalia, put her initial into the belt and made herself on the way.&rdquo;</p>
                            </blockquote>
                            <figure class="mb-4 d-flex align-items-center justify-content-center">
                                <div><img src="assets/images/person_4.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
                                <p>Bruce Rogers</p>
                            </figure>
        
                        </div>
                    </div>
        
                </div>
            </div -->
        
            <div class="site-section" id="pricing-section">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-7 text-center mb-5">
                            <span class="subtitle-1">Pricing</span>
                            <h2 class="section-title-1 font-weight-bold">Community Supported</h2>
                            <p>
                                We are community supported. In order to make it so everyone can enjoy Koblime,
                                we invite you to pay what you can. A monthly contribution helps keep our service going.
                            </p>
                            <a href="https://www.buymeacoffee.com/koblime/membership" target="_blank"><kob-coffee></kob-coffee> Buy Me Coffee</a>
                        </div>
                    </div>
                    <div class="row">
        
                        <!--
                                          <div class="col-lg-6 col-md-6 mb-4 mb-lg-0 pricing">
                                            <div class="border p-5 text-center rounded">
                                              <h3>Starter</h3>
                                              <div class="price mb-3"><span class="number">Free</span></div>
                                              <p class="text-muted mb-4">* Billed annualy or $10 per month</p>
                                              <ul class="list-unstyled ul-check text-left success mb-5">
                                                <li>Max 5 users</li>
                                                <li>29 local security</li>
                                                <li class="text-muted"><del>Desktop App</del></li>
                                                <li class="text-muted"><del>Email Support</del></li>
                                                <li class="text-muted"><del>Phone Support 24/7</del></li>
                                              </ul>
                                              <p><a href="#" class="btn btn-lg btn-secondary rounded-0 btn-block">Buy Now</a></p>
                                            </div>
                                          </div>
                                
                                          <div class="col-lg-6 col-md-6 mb-4 mb-lg-0 pricing">
                                            <div class="border p-5 text-center rounded">
                                              <h3>Professional</h3>
                                              <div class="price mb-3"><sup class="currency">$</sup><span class="number">72</span> <span
                                                  class="per">/year</span></div>
                                              <p class="text-muted mb-4">* Billed annualy or $30 per month</p>
                                              <ul class="list-unstyled ul-check text-left success mb-5">
                                                <li>Max 10 users</li>
                                                <li>29 local security</li>
                                                <li>Desktop App</li>
                                                <li>Email Support</li>
                                                <li class="text-muted"><del>Phone Support 24/7</del></li>
                                              </ul>
                                              <p><a href="#" class="btn btn-lg btn-primary rounded-0 btn-block">Buy Now</a></p>
                                            </div>
                                          </div>
                                          -->
        
                    </div>
                </div>
            </div>
        
        
            <!-- div class="site-section text-center">
                                      <div class="container">
                                        <h2 class="text-black mb-3">Join over 1,700 companies that trust us.</h2>
                                        <p class="mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                        <p><a href="#" class="btn btn-primary">Try it for free</a></p>
                                      </div>
                                    </div -->
        
            <footer class="site-footer bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <h2 class="footer-heading mb-4">About Us</h2>
                                    <p>We love to read and needed something to manage our books and annotations. We like to
                                        tinker so we
                                        built <i>koblime</i>.</p>
                                    <p>Why koblime? It rhymes with <i>sublime</i> and is short for
                                        <strong>ko</strong>bo-<strong>li</strong>brary-for-<strong>me</strong>.</p>
                                </div>
                                <div class="col-md-3 ml-auto">
                                    <h2 class="footer-heading mb-4">Legal</h2>
                                    <ul class="list-unstyled">
                                        <li><a href="/terms" class="smoothscroll">Terms</a></li>
                                        <li><a href="/privacy" class="smoothscroll">Privacy</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-3 footer-social">
                                    <h2 class="footer-heading mb-4">Follow Us</h2>
                                    <a class="pl-0 pr-3" target="_blank" href="https://twitter.com/nsainaney" ><span class="icon-twitter"></span></a>
                                    <a class="pl-3 pr-3" target="_blank" href="https://reddit.com/user/neuraltoxin"><span class="icon-reddit"></span></a>
                                    <a class="pl-3 pr-3" target="_blank" href="https://linkedin.com/in/nsainaney"><span class="icon-linkedin"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="row pt-5 mt-5 text-center">
                        <div class="col-md-12">
                            <div class="border-top pt-5">
                                <p class="copyright"><small>
                                        
                                        Copyright &copy; ${new Date().getFullYear()} Sainaney Ventures Inc.
                                         All rights reserved | Site template by <a href="https://colorlib.com"
                                            target="_blank">Colorlib</a>
                                        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                                    </small></p>
        
                            </div>
                        </div>
        
                    </div>
                </div>
            </footer>
        
        </div> <!-- .site-wrap -->
        `
    }
}
