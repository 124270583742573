import { customElement, property } from 'lit/decorators.js'
import { html, css } from 'lit'
import { Layout, View } from './view'
import { BaseStore, isBusy } from '../stores'

@customElement('kob-loading')
export class LoadingView extends View {

    render() {
        return html`
            <div class="flex flex-column" style="align-items:stretch;height:100%">
                <div class="loader">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        `
    }
}

@customElement('kob-busy')
export class BusyView extends View {

//     static styles = css`
//     ::slotted(*) { font-family: Roboto; }
//     ::slotted(p) { color: blue; }
//     div ::slotted(*) { color: red; }
//   `

    @property({ type: Array })
    stores: BaseStore[]

    render() {
        if (this.stores?.some(s => isBusy(s) ?
            s.busy || !s.isInitialized :
            !s.isInitialized
        ))
            return html`
                <kob-loading></kob-loading>
            `
        
        return ''
    }
}