import { deviceTypes, deviceNameTypes, deviceSchema, servicePaths, kobContentIDField } from '@konode-monorepo/kopanion-common'
import { computed, makeObservable, observable } from 'mobx'
import * as semver from 'semver'
import download from 'downloadjs'
import { MongoEntity, MongoEntityStore } from './mongo'
import { config } from './config'
import { ReactiveController, ReactiveControllerHost } from 'lit'
import { dayjs } from './extensions'
import { inject, Symbols } from '../di'
import { StatusStore } from './status'
import { ManualBackup } from './manualBackup'

declare global {
    interface Window {
        initSqlJs: any
    }
}
export interface DriveDoc {
    [kobContentIDField]?: string
    id: string
    name: string
    mimeType: string
    parentId: string
}
export interface Device extends MongoEntity {
    name?: string
    type?: deviceNameTypes
    uninstall?: boolean
    kepubify?: boolean
    version?: string
    lastSync?: Date
    driveDocs?: DriveDoc[]
    canDelete?: boolean
    $unset: { [k: string]: number }
    $addToSet: { driveDocs: DriveDoc }
    $pull: { driveDocs: Partial<DriveDoc> }
}

export type downloadCondition = 'success' | 'error' | 'cancel'
type downloadStatus =
    'no-directory-picker'
    | 'kobo-not-found'
    | downloadCondition

const notInstalled = 'Not installed'

export interface DownloadResult {
    status: downloadCondition
    message: string
}

interface KoboDirResult {
    status: downloadStatus
    koboDir?: FileSystemDirectoryHandle
}

export class DeviceStore extends MongoEntityStore<Device> implements ReactiveController {

    @inject(Symbols.stores.status)
    statusStore: StatusStore

    @observable backupProgress = null
    @observable backupProgressMessage: string = null
    @computed get backupHappening() { return !!this.backupProgress && !!this.backupProgressMessage }

    get canDirectInstall() { return !!window.showDirectoryPicker }
    @computed get name() { return this.entity?.name || this.pending?.name }
    @computed get type() { return this.entity?.type || this.pending?.type }
    @computed get version() { return this.entity?.version || notInstalled }
    @computed get lastSync() { return this.entity?.lastSync }
    @computed get isInstalled() { return this.version !== notInstalled }
    @computed get canDelete() { return this.entity?.canDelete }
    @computed get driveDocs() { return this.entity?.driveDocs }
    @computed get markedUninstall() { return !!this.entity.uninstall }
    @computed get markedKepubify() { return !!this.entity.kepubify }
    @observable lastSyncDisplay
    @computed get typeLabel() {
        const item = deviceTypes.find(dt => dt.value === this.type)
        return item?.label
    }
    @computed get isOutdated() {
        if (this.isInitialized, this.statusStore.isInitialized)
            return semver.valid(this.version) &&
                semver.valid(this.statusStore.appVersion) &&
                semver.lt(this.version, this.statusStore.appVersion)
    }


    createSchema = deviceSchema

    constructor() {
        super(servicePaths.device)
        makeObservable(this)
    }

    private timer
    beginTimer(host: ReactiveControllerHost, grid) {
        if (this.timer) return
        host.addController(this)
        const update = () => {
            this.lastSyncDisplay = this.lastSync ?
                //@ts-ignore
                `Synced ${dayjs().to(this.lastSync)}` :
                'Never synced'
            grid.requestContentUpdate()
            // console.log('NSX lsd')
        }
        this.timer = setInterval(update, 1000)
        update()
    }

    dispose() {
        // super.dispose()
        if (this.timer) clearInterval(this.timer)
    }

    async markUninstall(uninstall: boolean) {
        this.pending = uninstall ?
            { uninstall: true } :
            { $unset: { uninstall: 1 } }
        await this.patch()
    }

    async markKepubify(kepubify: boolean) {
        this.pending = kepubify ?
            { kepubify: true } :
            { $unset: { kepubify: 1 } }
        await this.patch()
    }

    inQueue = (bookID: string) => !!this.driveDocs?.find(d => d[kobContentIDField] === bookID)

    async addGoogleDoc(doc) {
        const { [kobContentIDField]: kobContentID, id, name, mimeType, parentId } = doc
        this.pending = {
            $addToSet: { driveDocs: {
                [kobContentIDField]: kobContentID,
                id,
                name,
                mimeType,
                parentId
            } }
        }
        await this.patch()
    }

    async removeGoogleDoc(id: string) {
        this.pending = {
            $pull: { driveDocs: { id } }
        }
        await this.patch()
    }

    protected getKoboDir = async (): Promise<KoboDirResult> => {
        try {
            // let koboDir = window.localStorage.getItem('kobo-folder')
            let koboDir
            const dirHandle = window.showDirectoryPicker
                ? await window.showDirectoryPicker({
                    startIn: 'desktop',
                    mode: 'readwrite'
                })
                : undefined

            if (!dirHandle) return { status: 'no-directory-picker' }

            for await (const entry of dirHandle.values())
                if (entry.name === '.kobo') {
                    koboDir = entry
                    break
                }

            if (!koboDir) return { status: 'kobo-not-found' }
            return { status: 'success', koboDir }
            // return entry as FileSystemDirectoryHandle
        }
        catch(ex) { 
            if (ex.code === 20) return {status : 'cancel' }
            return {status : 'error' }
        }
    }

    protected async getKoboRootFileBlob() {
        const token = window.localStorage.getItem(config.storageKey)
        const response = await fetch(`${config.url}/${servicePaths.device}/${this.id}?file`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token}`
            })
        })
        // const filename = response.headers.get("content-disposition")
        return await response.blob()
    }


    protected statusToResult = (result: downloadStatus): DownloadResult => {
        let message: string
        let status: downloadCondition

        switch (result) {
            case 'kobo-not-found':
                message = 'The .kobo directory was not found. Please make sure you select the Kobo root folder.'
                status = 'error'
                break
            case 'error':
                message = 'An unknown error occurred'
                status = 'error'
                break
            case 'cancel':
                message = 'User cancelled'
                status = 'cancel'
                break
            case 'success':
                message = 'You can safely disconnect the Kobo'
                status = 'success'
                break
        }
        return { status, message }

    }

    // https://web.dev/file-system-access
    protected installFileDirect = async (): Promise<downloadStatus> => {
        const result = await this.getKoboDir()
        if (result.koboDir) {
            const fileHandle = await result.koboDir.getFileHandle('KoboRoot.tgz', { create: true })
            const writable = await fileHandle.createWritable()
            await writable.write(await this.getKoboRootFileBlob())
            await writable.close()
            return 'success'
        }
        return result.status
    }

    async installKoblime(): Promise<DownloadResult> {
        const result = await this.installFileDirect()

        if (result === 'no-directory-picker') {
            // const filename = response.headers.get("content-disposition")
            const blob = await this.getKoboRootFileBlob()
            download(blob, 'KoboRoot.tgz', 'application/x-tar')
            return {
                status: 'success',
                message: 'Please copy the downloaded KoboRoot.tgz to the KOBOReader/.kobo hidden folder'
            }
        }

        return this.statusToResult(result)
    }

    async backup(): Promise<DownloadResult> {
        let db: any
        try {
            const result = await this.getKoboDir()
            if (!result.koboDir) return this.statusToResult(result.status)

            const SQL = await window.initSqlJs({
                locateFile: () => 'https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.8.0/sql-wasm.wasm'
            })

            const fileHandle = await result.koboDir.getFileHandle('KoboReader.sqlite')
            const fileData = await fileHandle.getFile()
            const data = await fileData.arrayBuffer()
            db = new SQL.Database(new Uint8Array(data))
            const backup = new ManualBackup(this, db, this.id as string)
            await backup.doBackup()

            this.pending = { version: 'Wired backup' }
            await this.patch()

            return { status: 'success', message: 'Backup completed' }
        }
        finally {
            if (db) db.close()
        }
    }
}