import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Symbols, inject } from '../di'
import { GoogleDrive, GoogleDrivePickerParams, GoogleDrivePickerCallback } from '../stores'
import { View } from './view'

@customElement('kob-drive-pick')
export class DrivePick extends View {

    @inject(Symbols.stores.googleDrive)
    googleDrive: GoogleDrive

    params: GoogleDrivePickerParams

    @property({ type: Object })
    pickerCallback: GoogleDrivePickerCallback

    @property({ type: String })
    queryString = ''

    @property({ type: String })
    title = 'Select file'

    @property({ type: String })
    label = 'Add Book'

    @property({ type: String })
    icon = 'lumo:plus'

    handlePick = (e) => {
        gapi.load('picker', () => {
            const view = new google.picker.DocsView(google.picker.ViewId.DOCS)
            view            
            //@ts-ignore
            .setQuery(this.queryString)

            if (!e.altKey) // unfiltered select
                view.setMimeTypes('application/epub+zip')

            const picker = new google.picker.PickerBuilder()
                .enableFeature(google.picker.Feature.NAV_HIDDEN)
                .setAppId(this.params.appId)
                .setDeveloperKey(this.params.developerKey)
                .setOAuthToken(this.params.token)
                .addView(view)
                .addView(new google.picker.DocsUploadView())
                .setTitle(this.title)
                .setCallback(this.pickerCallback)
                .build()
            picker.setVisible(true)
        })
    }

    render() {
        return html`
                <vaadin-button theme="icon small" @click=${this.handlePick}>
                    <vaadin-icon icon="${this.icon}"></vaadin-icon>
                    ${this.label}
                </vaadin-button>
            `
    }

    async connectedCallback() {
        this.params = await this.googleDrive.getPickerParams()
        await super.connectedCallback()
    }
}