import { Route } from '@vaadin/router'
import { container, Symbols } from '../di'
import { MeStore } from '../stores/me'

export type ViewRoute = Route & {
    title?: string
    icon?: string
    requiresLogin?: boolean
    children?: ViewRoute[]
}

const isAuthenticated = async () => {
    const meStore = container.get<MeStore>(Symbols.stores.me)
    await meStore.initialized
    return meStore.isAuthenticated
}

export const hasAccess = async (route: Route) => {
    const viewRoute = route as ViewRoute
    return viewRoute.requiresLogin ?
        await isAuthenticated() :
        true
}

const loginRedirect = async (context, command) => {
    if (!await hasAccess(context.route))
        return command.redirect('/login')
}

// place routes below (more info https://hilla.dev/docs/routing)
export const views: ViewRoute[] = [{
        path: '/',
        component: 'kob-book-list',
        requiresLogin: true,
        icon: '',
        title: 'Books',
        action: loginRedirect
    }, {
        path: '/devices',
        component: 'kob-device-list',
        requiresLogin: true,
        icon: '',
        title: 'Devices',
        action: loginRedirect
    }, {
        path: '/highlights',
        component: 'kob-highlight-list',
        requiresLogin: true,
        icon: '',
        action: loginRedirect
    }
]

export const routes: ViewRoute[] = [
    {
        path: '/login',
        component: 'kob-login',
        requiresLogin: false,
        icon: '',
        title: 'Login',
        action: async (_context, _command) => {
            if (await isAuthenticated())
                return _command.redirect('/')
            await import('../views/login')
        },
    }, {
        path: '/terms',
        component: 'kob-terms',
        icon: '',
        title: 'Terms of Use',
        requiresLogin: false,
    }, {
        path: '/privacy',
        component: 'kob-privacy',
        icon: '',
        title: 'Privacy Policy',
        requiresLogin: false,
    },
    {
        path: '/',
        component: 'kob-main',
        children: [...views],
    },
]