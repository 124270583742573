import { css, html } from 'lit'
import { Router } from '@vaadin/router'
import { customElement, property } from 'lit/decorators.js'
import { Layout, View } from './view'
import { Symbols, inject } from '../di'
import { MeStore } from '../stores'

@customElement('kob-app')
export class App extends View {
    // Declare reactive properties
    @property()
    name?: string = 'World'

    @inject(Symbols.stores.me)
    meStore: MeStore

    // Render the UI as a function of component state
    handleLogout = async () => await this.meStore.logout()

    render() {
        return html`
            <p>Hello, ${this.name}!</p>
            <vaadin-button theme="primary" @click=${this.handleLogout}>Logout</vaadin-button>
        `
    }
}