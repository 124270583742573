import { Container, injectable } from 'inversify'
import getDecorators from 'inversify-inject-decorators'

const container = new Container({ skipBaseClassChecks: true })
const decorators = getDecorators(container)
const inject = decorators.lazyInject
type Factory<T> = (...args: any[]) =>  | T

export {
    container,
    injectable,
    inject,
    Factory
}