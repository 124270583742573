import { servicePaths, validateISBN, kobLinkField, kobContentIDField } from '@konode-monorepo/kopanion-common'
import { ReactiveControllerHost } from 'lit'
import { computed, makeObservable, observable, observe } from 'mobx'
import { MongoEntityStore } from './mongo'
import { dayjs } from './extensions'
import { GoogleBooks } from './googleBooks'
import { Content } from './content'
import { inject, Symbols } from '../di'
import { DeviceListStore } from './deviceList'
import { DeviceStore } from './device'

export interface Book extends Content {
    // After hooks
    highlightCount: number
}

export type BookType = 'epub' | 'kepub' | 'pdf' | 'cbr' | 'xhtml' | 'unknown'

export class BookStore extends MongoEntityStore<Book> {
    @inject(Symbols.stores.deviceList)
    devicesList: DeviceListStore

    private static isbnCache = []
    @observable coverURL = null
    
    @computed get ISBN() { return this.entity?.ISBN }
    @computed get validISBN() { return this.ISBN && validateISBN(this.ISBN) }
    @computed get title() { return this.entity?.Title }
    @computed get author() { return this.entity?.Attribution }
    @computed get percentRead() { return this.entity?.___PercentRead }
    @computed get completed() { return this.entity?.ReadStatus === 2 }
    @computed get lastRead() { return this.entity?.DateLastRead }
    @computed get highlightCount() { return this.entity?.highlightCount }
    @computed get hasLink() { return !!this.entity?.[kobLinkField] }
    @computed get googleDocLink() { return this.hasLink
        ? {
            [kobContentIDField]: this.id,
            ...this.entity?.[kobLinkField] 
        }
        : undefined
    }
    @computed get type():BookType { 
        switch(this.entity?.MimeType) {
            case 'application/epub+zip': return 'epub'
            case 'application/x-kobo-epub+zip': return 'kepub'
            case 'application/pdf': return 'pdf'
            case 'application/x-cbr': return 'cbr'
            case 'application/xhtml+xml': return 'xhtml'
            default: return 'unknown'
        }
        
    }
    
    @observable lastReadDisplay

    constructor() {
        super(servicePaths.book)
        makeObservable(this)
    }

    init() {
        super.init()

        this.disposers.push(observe(this, 'entity', async () => {
            try {
                if (!this.validISBN)  return
                // this.coverURL = `https://covers.openlibrary.org/b/isbn/${this.ISBN}-M.jpg`
                // return
                const cacheValue = BookStore.isbnCache[this.entity.ISBN]
                if (cacheValue) {
                    this.coverURL = cacheValue
                    return
                }

                const searchParams = new URLSearchParams({
                    q: `isbn:${this.entity.ISBN}`,
                    filter: 'ebooks',
                    printType: 'BOOKS',
                    projection: 'LITE',
                })
                const url = `https://www.googleapis.com/books/v1/volumes?${searchParams.toString()}`

                const result = await fetch(url)
                const body = await result.json()
                if (body.totalItems > 0) {
                    const url = body.items[0].volumeInfo?.imageLinks?.smallThumbnail
                    this.coverURL = url
                    BookStore.isbnCache[this.entity.ISBN] = url
                }
            }
            catch { /* ignore */ }
        }))
    }

    setCompleted = (completed: boolean) =>
        this.pending.ReadStatus = completed ? 2 : 1

    matchBook = async (book: GoogleBooks) => {
        const { title, author, ISBN } = book
        this.pending = {
            ...(title ? { Title: title } : undefined),
            ...(author ? { Attribution: author } : undefined),
            ...(ISBN ? { ISBN } : undefined),
        }
        return await this.save()
    }

    private timer
    beginTimer(host: ReactiveControllerHost, grid) {
        if (this.timer) return
        host.addController(this)
        const update = () => {
            this.lastReadDisplay = this.lastRead ?
                //@ts-ignore
                `Last read ${dayjs().to(this.lastRead)}` : 
                'Never read'
            grid.requestContentUpdate()
        }
        this.timer = setInterval(update, 1000)
        update()
    }

    async linkGoogleDoc(doc) {
        const { id, name, mimeType, parentId } = doc
        this.pending = {
            [kobLinkField]: {
                id,
                name,
                mimeType,
                parentId
            }
        }
        await this.patch()
    }

    dequeueFromDevice = async (deviceStore?: DeviceStore) => deviceStore 
        ? deviceStore.removeGoogleDoc(this.googleDocLink?.id)
        : await this.devicesList.inQueue(this.id)?.removeGoogleDoc(this.googleDocLink?.id)

    unlinkGoogleDoc = async () => {
        // Remove it from any device queue
        await this.dequeueFromDevice()
        this.pending = { $unset: { [kobLinkField]: 1 } }
        await this.patch()
    }

    dispose() {
        super.dispose()
        if (this.timer) clearInterval(this.timer)
    }
}