import { Notification } from '@vaadin/notification'
import { html } from 'lit'

export const showError = (error: Error) => {
    Notification.show(error.message, { position: 'top-center', theme: 'error' })
}

export const showBooksUpdating = (entity) => {
    Notification.show(
        html`<kob-book-notification .entity=${entity}></kob-book-notification>`, { position: 'top-end' }
    )
}