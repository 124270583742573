import { servicePaths, kobLinkField } from '@konode-monorepo/kopanion-common'
import { makeObservable, runInAction, override, observable, reaction } from 'mobx'
import { Symbols } from '../di'
import { Book, BookStore } from './book'
import { showBooksUpdating } from './extensions/error'
import { MongoEntityListStore } from './mongo'

const defaultSort = { DateLastRead: -1, _id: 1 }
export class BookListStore extends MongoEntityListStore<Book, BookStore> {
    sortBy = [
        { label: 'Last Read', value: 'last-read', sort: { DateLastRead: -1 } },
        { label: 'Percent Read', value: 'percent-read', sort: { ___PercentRead: -1 } },
        { label: 'Title', value: 'title', sort: { Title: 1 } },
        { label: 'Author', value: 'author', sort: { Attribution: 1 } }
    ]

    @override sort: any = { $sort: defaultSort }
    @observable searchText: string = null
    @observable searchLinked = false

    constructor() {
        super(servicePaths.book, Symbols.stores.book)
        makeObservable(this)
    }

    protected onCreated(entity) {
        super.onCreated(entity)
        if (!this.inNextLoad)
            showBooksUpdating(entity)
    }

    async serviceChanged() {
        await super.serviceChanged()
        this.disposers.push(reaction(
            () => [this.searchLinked, this.searchText],
            () => {
                runInAction(() => {
                    this.reset()
                    const filter = {
                        ...(this.searchText ? { $text: { $search: this.searchText } } : undefined),
                        ...(this.searchLinked ? { [kobLinkField] : { $exists: true } } : undefined)
                    }

                    this.filter = this.searchLinked || this.searchText
                        ? filter
                        : null
                })
            }
        ))
    }

    reset() {
        runInAction(() => {
            super.reset()
            this.sort = { $sort: defaultSort }
        })
        
    }

    setSortBy = (value:string) => {
        const by = this.sortBy.find(f => f.value === value)
        if (by) this.sort = { $sort: by.sort }
    }
}
