import { BaseStore } from '../base'
import { showError } from './error'

export const isBusy = (store: BaseStore | Busy): store is Busy => 
    typeof (store as Busy).busy === 'boolean'

export interface Busy {
    busy: boolean
}

type wrappedFn = () => any | Promise<any>

export const wrapBusy = async (store: BaseStore | Busy, fn: wrappedFn) => {
    if (store && isBusy(store)) {
        try {
            store.busy = true
            return await fn()
        }
        catch(ex) {
            showError(ex)
        }
        finally {
            store.busy = false
        }
    }
    else 
        return await fn()
}