import { observable } from 'mobx'
import { EntityStore } from './entity'
import { Busy } from './extensions'
import { EntityListStore } from './list'


export interface IDEntity {
    id: string
}

export class IDEntityStore<E extends IDEntity = IDEntity> extends EntityStore<E> implements Busy {
    @observable busy: boolean
    readonly idKey: 'id'
}

export class IDEntityListStore<
    E extends IDEntity = IDEntity,
    S extends IDEntityStore<E> = IDEntityStore<E>> extends EntityListStore<E, S> implements Busy {

    @observable busy: boolean
    readonly idKey: 'id'
}