import { Params } from '@feathersjs/client'
import { ajv } from '@konode-monorepo/kopanion-common'
import { action, computed, observable, runInAction } from 'mobx'
import { BaseStore } from './base'
import { wrapBusy, Busy } from './extensions'
import { EntityListStore } from './list'

export abstract class EntityStore<E = any, K extends keyof E = keyof E> extends BaseStore<E> {
    @observable entity: E = null
    @observable pending: Partial<E> = { }

    abstract readonly idKey: K | null

    createSchema
    patchSchema

    @computed get id() { return this.entity?.[this.idKey] }
    @computed get isNew() { return !this.entity?.[this.idKey] }

    mine(entity: Partial<E>): boolean {
        // Can't be equal if either are undefined
        if (!entity || !(this.entity || this.pending))
            return false
        // We have to check pending because on create
        // sometimes the created socked event precedes
        // the result from the create call!
        return entity[this.idKey] === this.entity[this.idKey] ||
            entity[this.idKey] === this.pending[this.idKey]
    }

    async save() {
        return this.isNew ?
            this.create() :
            this.patch()
    }

    private validate = (schema) => ajv.validate(schema, this.pending)

    createValidate() { 
        if (this.createSchema)
            return this.validate(this.createSchema)
        return true  /* to be overridden */
    }

    patchValidate() { 
        if (this.patchSchema)
            return this.validate(this.patchSchema)
        return true  /* to be overridden */
    }

    async serviceChanged() {        
        await super.serviceChanged()
        if (this.service) {
            this.service.on('updated', this.onUpdated)
            this.service.on('patched', this.onPatched)
            this.service.on('removed', this.onRemoved)
        }
    }

    reset() {
        this.pending = null
    }

    @action.bound
    protected onUpdated(entity) {
        if (!this.mine(entity)) return
        this.entity = entity
        this.reset()
    }

    @action.bound
    protected onPatched(patch) {
        if (!this.mine(patch)) return
        this.entity = Object.assign({}, this.entity, patch)
        this.reset()
    }

    @action.bound
    protected onRemoved(entity) {
        if (!this.mine(entity)) return
        delete this.entity
        this.reset()
    }

    async create(p?: Params<any>) {
        this.disposeGuard()

        return await wrapBusy(this, async () => {
            if (!this.createValidate()) return false
            const entity = await this.service.create(this.pending, p) as E
            this.reset()
            runInAction(() => this.entity = entity)
            return true
        })
    }

    async patch() {
        this.disposeGuard()

        return await wrapBusy(this, async () => {
            if (!this.patchValidate()) return
            await this.service.patch(this.id.toString(), this.pending) as E
            this.reset()
            return true
        })
    }

    async remove() {
        this.disposeGuard()

        return await wrapBusy(this, async () => {
            await this.service.remove(this.id.toString()) as E
            this.reset()
            return true
        })
    }
}