
declare global {
    interface Element {
        validate(): boolean
    }
}

export const validateAll = (element: Element) => {
    let result = true
    if (element.children)
        for(let i = 0; i < element.children.length; i++)
            result = validateAll(element.children[i]) && result
        
    if (element.validate)
        return element.validate() && result
    return result
}