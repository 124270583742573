import { injectable, postConstruct } from 'inversify'
import { AuthenticationClient } from '@feathersjs/authentication-client'
import { Application } from '@feathersjs/client'
import { ReactiveController } from 'lit'
import { observable, makeObservable, observe, runInAction } from 'mobx'
import EventEmitter from 'events'
import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import { ServiceMethods } from '@feathersjs/feathers'
import auth from '@feathersjs/authentication-client'
import { config } from './config'

@injectable()
export class BaseStore<E = any> implements ReactiveController {
    static app: Application
    static instanceCounter = 0

    static get authClient() { return BaseStore.app as Application<AuthenticationClient> }

    @observable serviceName: string
    @observable disposed = false
    protected disposers = []
    
    instanceID
    service: ServiceMethods<E> & EventEmitter
    get storage() { return window.localStorage }
    
    private initResolver
    @observable isInitialized = false
    initialized = new Promise(resolve => this.initResolver = resolve)
    protected markInitialized = () => {
        runInAction(() => this.isInitialized = true)
        this.initResolver()
    }

    constructor(serviceName?: string) {
        this.instanceID = BaseStore.instanceCounter++
        makeObservable(this)        
        this.serviceName = serviceName
    }

    @postConstruct()
    init() {
        this.disposers.push(
            observe(this, 'serviceName', async () => await this.serviceChanged(), true)
        )
    }

    disposeGuard() {
        if (this.disposed)
            throw new Error(`Attempted to use disposed instance ID ${this.instanceID} for service: ${this.serviceName}`)
    }

    hostDisconnected() {
        this.dispose()
    }

    dispose() {
        if (this.disposed) return
        for(const disposer of this.disposers)
            disposer()
        this.disposed = true
    }

    private static ensureApp() {
        if (BaseStore.app) return

        BaseStore.app = feathers()// as any as Application<any>
        const opts = {
            transports: ['websocket'],
            path: '/api/ws/',
            upgrade: false
        }
        const socket = io(config.url, opts)

        BaseStore.app.configure(socketio(socket))

        BaseStore.app.configure(auth({
            path: '/api/authentication',
            storage: window.localStorage,
            storageKey: config.storageKey
        }))
    }

    async serviceChanged() {
        if (!this.serviceName) return
        BaseStore.ensureApp()
        this.service = BaseStore.app.service(this.serviceName)
    }
}