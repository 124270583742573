import { customElement, query, state } from 'lit/decorators.js'
import { html } from 'lit'
import { View } from './view'
import { Symbols, inject } from '../di'
import { DeviceListStore } from '../stores'
import { DeviceDownload } from './device'


@customElement('kob-device-null')
export class DeviceNullView extends View {
    @inject(Symbols.stores.deviceList)
    devicesList: DeviceListStore

    @query('kob-device-create')
    deviceCreateDialog

    @query('kob-device-download')
    deviceDownloadDialog: DeviceDownload

    deviceName = () => this.devicesList.firstDevice?.name

    handleCreate = () => {
        this.deviceCreateDialog.deviceStore = this.devicesList.newStore()
        this.deviceCreateDialog.dialogOpened = true
    }

    handleInstall = () => {
        this.deviceDownloadDialog.deviceStore = this.devicesList.firstDevice
        this.deviceDownloadDialog.dialogOpened = true
    }

    render() {
        return html`
            <kob-device-create></kob-device-create>
            <kob-device-download></kob-device-download>
            <div class="flex flex-row" style="justify-content: center;align-items:center;height:-webkit-fill-available;overflow:auto;">
                <vaadin-horizontal-layout theme="spacing padding" style="align-items:center;width:80%;">
                    <layout-item>
                        ${this.renderImage()}
                    </layout-item>
                    <layout-item>
                        <div style="overflow:auto">
                            <p>There are no Devices in your account. To get started, follow the steps below:</p>
                            <h3>1. Add a device</h3>
                            <p>
                                Each Device you own is synced separately. When you add a device,
                                we generate a KoboRoot.tgz file specific to your device.
                            </p>
                            ${this.renderDeviceStep()}
                            <h3>2. Install koblime on your device</h3>
                            <p>
                                Connect your Kobo to your computer and copy the KoboRoot.tgz file into
                                the <b>.kobo</b> hidden folder on the device. Then, safely unplug
                                the device. The device will restart.
                            </p>
                            ${this.renderInstallStep()}
                            <h3>3. Content will sync over WiFi</h3>
                            <p>The next time your Kobo connects to the internet, your content books and highlights will sync here.
                            </p>
                        </div>
                    </layout-item>
                </vaadin-horizontal-layout>
            </div>
        `
    }

    renderInstallStep = () => this.devicesList.hasEntities ?
        html`
            <p>Click the following button to install Koblime for <b>${this.deviceName()}</b>:</p>
            <vaadin-button theme='primary' @click=${() => this.handleInstall()}>Install on ${this.deviceName()}</vaadin-button>
        ` :
        html`<span theme="badge">Waiting for step 1 to complete</span>`

    renderDeviceStep = () => this.devicesList.hasEntities ?
        html`
            <span theme="badge success">Device&nbsp;<b>${this.deviceName()}</b>&nbsp;has been added. Move on to Step 2.</span>
        ` :
        html`
            <p>Click the following button to add a Device:</p>
            <div>
                <vaadin-button theme="primary" @click=${this.handleCreate}>Add Device</vaadin-button>
            </div>
        `

    renderImage = () => html`
        <svg width="468.894" height="407.25" viewBox="0 0 468.893 407.25" xmlns="http://www.w3.org/2000/svg">
            <path d="M1722.41 462.96c-82.93 15.52-58.75 132.05-48.35 182.99l60.97 434.76c10.4 50.94 60.59 84.1 111.52 73.7l18.7-3.81c50.93-10.4 104.26-65.36 93.86-116.3l-77.12-471.51c-20.22-71.94-69.21-116.74-159.58-99.83" style="fill:#f0a17d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2726.16 1156.32c207.45-9.52 192.63-330.1 100.76-678.81l-426.76-18.12 89.66 645.92 236.34 51.01" style="fill:#455d7a;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1199.34 459.32s416.04-32.02 536.67-.92c158.44 40.85 184.98 164.56 132.69 250.34-40.83 39.55-82.8 19.59-168.39-.93-41.68-9.99-507.31-116.26-521.71-122.39-28.65-57.82 20.74-126.1 20.74-126.1" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1868.7 708.74c-40.83 39.55-82.8 19.59-168.39-.93-9.19-2.2-38.98-9.08-79.99-18.53 56.6-94.44 99.04-178.04 123.44-228.7 151.46 42.86 176.36 163.82 124.94 248.16" style="fill:#f0a17d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1829.88 997.91c-3.61 9.61-142.3 61.24-159.72 54.04-17.41-7.21-45.62-36.44-45.62-36.44 12.44-39.93 225.15-173.28 273.79-180.96 48.63-7.68-64.85 153.75-68.45 163.36" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1800.64 1373.12c-24.23-32.4-56.16-135.6-85.13-198.95-53.95-117.99-90.97-158.66-90.97-158.66 76.5 16.84 222.45-103.55 275.59-171.1 49.72-63.2 63.23 328.22 63.23 328.22l-162.72 200.49" style="fill:#f95959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m1933.88 1150.8-38.05 42.37-68.75 76.55-2.42 4.15 32.93-199.15-38.02-150.1c32.37-35.73 56.65-45.04 74.12-67.25 12.7-16.15 7.44-17.62 16.83 7.4l23.36 286.03m887.86-239.61c-56.14-15.31-209.93-24.96-209.93-24.96l19.81 151.31 91.87-65.75 98.25-60.6" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m2404.08 1529.73-333.02-30.74-270.42-125.86 68.98-285-44.77-678.59h655.99c18.87 220.38 81.14 685.73 81.14 685.73l150.7 269.2-308.6 165.26" style="fill:#f95959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2544.19 1281.94c-44.43-101.77 15.31-172.92 15.31-172.92 1.2-79.25 19.36-123.83 19.36-123.83l-46.56-75.75 4.08-67.73-22.49-362.14s-91.21-1.79-88.81.01c2.4 1.8 44.66 590.08 44.66 590.08s9.6 126.27 74.45 212.28m-498.72 280.37c157.66 82.76 309.57 79.5 309.57 79.5l-174-285.63-135.57 206.13" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2016.25 1782.37c53.03-95.01 100.05-292.53 116.11-395.77 91.26-78.34 197.95 2.3 229.66 67.21-35.6 194.92-59.16 489.16-59.16 489.16s-146.25-89.67-286.61-160.6" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m2104.02 1526.05 239.11 46.79c-24.8 176.99-45.34 416.74-45.34 416.74s-11.61-56.84-39.05-73.08l-204.28-221.4c15.4-42.57 37.43-122.02 49.56-169.05" style="fill:#f0a17d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2045.47 1562.31c16.14-14.42 44.71-45.27 44.71-45.27l79.27-145.29 40.33 144.07 145.26 125.99 90.73-175.9c-43.42-87.04-264.92-137.64-267.58-138.1-85.53 61.05-159.57 127.47-159.57 127.47l26.85 107.03" style="fill:#f95959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m2037.08 1343.88 52.85 139.45 84.65-146.97-2.97-9.69-138.57-5.65-13.87 131.06 17.91-108.2" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m2216.58 1507.86-46.79-181.26 119.06 26.8 122.19 153.38-123.73-128.68-70.73 129.76" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m2172.06 1335.4-22.43-61.53.16-47.65-76.14-556.51 8.82 1.31 73.53 557.45.3 41.7 23.06 67.79-7.3-2.56" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m2073.65 669.71 8.82 1.31-17.42-264.1h-8.1l16.7 262.79" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2740.98 1022.1c-102.69 20.36-211.4-17.73-220.14-31.97-8.74-14.23 32.87-357.07 39.34-392.54 6.46-35.46 13.61-148.54 13.61-148.54 109.71-30.74 210.63 7.62 214.4 85.02 2.35 48.31-13.62 133.26-16.98 161.3-10.53 87.67-30.23 326.73-30.23 326.73" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2712.68 1364.47c17.37-35.07 37.86-114.1 46.82-174.69 13.9-94.06 49.18-198.88 62.24-278.59-97.64 46.73-330.99-24.96-330.99-24.96-3.27 115.64 3.06 213.53 4.02 238.1 39.81 248.58 162.34 249.47 217.91 240.14" style="fill:#f95959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2676.26 2286.84c14.75 32.61 51.83 47.81 87.21 66.55 19.32 7.51 36.87 19.58 50.88 35.57 7.44 7.81 13.79 16.72 18.53 27.2.33.71.51 1.38.8 2.08.48.99.98 1.97 1.44 2.98 29.69 65.65.57 142.92-65.04 172.61-65.62 29.68-142.89.53-172.58-65.11-.66-1.46-1.26-2.93-1.86-4.4-.12-.24-.25-.43-.36-.68-4.17-9.22-6.56-18.55-7.73-27.96-3.77-23.32-1.03-46.86 7.32-68.23 9.29-37.71 21.34-74.43 6.69-106.82l-3.62-4.57 26.15-24.98-2.4-5.31 8.75-.76 6.35-6.07 2.4 5.3 36.03-3.14 1.04 5.74" style="fill:#f5f9fd;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -132.348 442.972)"/>
            <path d="M2750.43 2424.12c-8.19-5.67-16.11-7.9-22.22-6.35 13.08 19.47 24.4 31.98 32.45 35.09 2.02.78 3.72.87 5.06.26 1.8-.81 2.72-2.07 2.89-3.95.59-6.48-7.57-17.71-18.18-25.05zm-34.96 14.11c-.92 12.61 3.58 26.84 9.82 31.07 2.08 1.41 4.17 1.6 6.38.6.07-.03.15-.06.22-.1 7-3.4 3.56-13.18 2.34-16.09-3.11-7.42-9.89-15.71-18.11-20.4-.35 1.81-.55 3.5-.65 4.92zm-31.39 17.56c.74 10.8 5.3 21.96 11.51 26.69 2.88 2.18 5.77 2.64 8.59 1.36 1.96-.88 2.22-2.09 2.23-3.05.07-5.88-10.32-17.05-22.33-25zm-25.67-7.42c-8.06 6.63-5.09 32.73 3.02 45.42 2.13 3.34 5.43 6.95 9.11 5.29.04-.02.07-.04.11-.05 4.38-2.11-.12-23-9.11-51.86-1.29.15-2.34.55-3.13 1.2zm114.52 1.19c-.31 3.45-2.19 6.05-5.43 7.52-2.43 1.1-5.26 1.04-8.4-.18-8.87-3.42-21-16.69-34.91-37.39-3.54 2.25-5.66 5.88-6.94 9.58 9.58 5.13 17.45 14.52 20.98 22.94 4.19 10 2.48 18.3-4.45 21.67-3.81 1.94-7.48 1.52-10.92-.81-7.62-5.16-12.77-20.53-11.72-34.97.17-2.32.49-4.51.95-6.55-5.09-2.03-10.57-2.55-15.97-.63-7.99 2.92-10.81 10.38-11.71 16.19-.19 1.2-.32 2.44-.38 3.69 13.62 8.38 26.82 21.66 26.72 30.22-.03 3.11-1.73 5.58-4.78 6.95-4.33 1.96-8.82 1.32-13-1.86-7.82-5.95-13.25-19.93-13.33-32.89a68.62 68.62 0 0 0-4.47-2.35c-3.37-1.61-6.42-2.7-9.02-3.22 8.8 29.06 13.22 52.18 6.38 55.47-2.94 1.34-8.85 2.45-14.76-6.81-8.34-13.06-12.77-42.35-2.11-51.11 1.26-1.04 2.77-1.7 4.56-2.03-12.16-38.19-31.46-88.77-48.49-128.47l3.99-1.71c.49 1.15 31.77 74.7 49.02 130.06 3.51.37 7.64 1.67 12.3 3.91.94.45 1.88.93 2.83 1.43.07-.65.15-1.3.25-1.94 1.54-9.87 6.68-16.87 14.55-19.62 6.32-2.24 12.71-1.68 18.62.62 1.83-5.05 4.71-8.93 8.5-11.38-19.07-29.14-41.25-71.64-62.89-121.6l3.98-1.73c21.49 49.62 43.86 92.45 62.83 121.43 7.57-2.57 17.34-.25 27.19 6.56 10.33 7.15 20.87 19.75 20.03 29.01" style="fill:#cadae8;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -132.348 442.972)"/>
            <path d="m2632.13 2246.13-32.12 14.53c-3.95 1.79-5.72 6.48-3.93 10.42l.69 1.53c1.78 3.94 6.47 5.71 10.42 3.92l32.12-14.53c3.95-1.78 5.72-6.47 3.93-10.42l-.69-1.52c-1.78-3.95-6.47-5.72-10.42-3.93" style="fill:#eaf4fe;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -132.348 442.972)"/>
            <path d="m2647.87 2247.93-56.9 25.74c-5.57 2.52-8.07 9.14-5.55 14.72l16.1 35.58a2.747 2.747 0 0 0 3.62 1.37l72.18-32.65a2.758 2.758 0 0 0 1.37-3.63l-16.1-35.58c-2.52-5.57-9.15-8.07-14.72-5.55" style="fill:#cadae8;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -132.348 442.972)"/>
            <path d="m2665.62 2265.9-72.87 32.96c-1.18.54-1.71 1.94-1.18 3.12l.34.75a2.357 2.357 0 0 0 3.12 1.18l72.87-32.96c1.18-.54 1.71-1.94 1.18-3.12l-.34-.75a2.365 2.365 0 0 0-3.12-1.18m6.02 13.32-72.86 32.97a2.353 2.353 0 0 0-1.18 3.12l.34.75a2.358 2.358 0 0 0 3.12 1.17l72.87-32.96a2.358 2.358 0 0 0 1.17-3.12l-.33-.75a2.382 2.382 0 0 0-3.13-1.18m-11.96-26.44-72.86 32.97a2.353 2.353 0 0 0-1.18 3.12l.34.75c.53 1.18 1.94 1.71 3.12 1.18l72.87-32.97a2.358 2.358 0 0 0 1.17-3.12l-.33-.75a2.382 2.382 0 0 0-3.13-1.18" style="fill:#eaf4fe;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -132.348 442.972)"/>
            <path d="M1814.31 2366.46c-48.37-85.6-44.72-205.93-32.82-258.25 8.04-35.35 88.55-139.33 100.41-201.13 11.85-61.8 5.71-100.47 38.88-153.63 33.16-53.15 135.73-190.31 174.09-195.24 38.37-4.94 147.36 45.46 194.26 238.95 46.9 193.5 6.5 330.41.01 363.22s10.53 204.61-75.11 234.82c-85.63 30.21-399.72-28.74-399.72-28.74" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2276.73 1957.07s-21.84 98.59-24.43 104.1c-42.67 105.91-108.92 213.39-108.92 213.39 33.89 28.53 70.69 56.28 70.69 56.28s-151.65 26.13-283.97-12.99c-132.32-39.12-136.49-45.36-136.49-45.36-15.02-29.28-18.73-77.07-18.93-113.34-17.5 21.71-82.64 135.53-80.01 162.99 2.63 27.47 73.49 48 81.2 56.96 7.7 8.96 38.65 99.68 99.14 130.38 60.49 30.7 184.69-24.99 232.17-41.2 47.48-16.2 156.99-52.55 185.05-75.75 28.06-23.19 48.14-254.34 28.31-338.29-19.83-83.96-43.81-97.17-43.81-97.17" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1942 1861.21c30.87-25.56 83.76.71 92.85 9.78-9.23-46.57-32.77-50.13-56.95-42.78-24.19 7.34-35.9 33-35.9 33" style="fill:#f08d6c;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2048.87 2012.01c7.08-16.81 22.87-26.21 35.27-20.99 12.41 5.22 16.73 23.09 9.65 39.9-7.07 16.81-22.86 26.21-35.27 20.99-12.4-5.22-16.72-23.08-9.65-39.9" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2047.73 2033.29c-.1-6.28 3.57-11.44 8.2-11.52 4.63-.08 8.48 4.95 8.58 11.23.11 6.28-3.56 11.44-8.19 11.52-4.64.08-8.48-4.95-8.59-11.23" style="fill:#455d7a;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1896.9 1966.05c5.88-15.37 19.17-24.58 29.69-20.56 10.53 4.02 14.29 19.74 8.42 35.12-5.88 15.38-19.17 24.59-29.69 20.57-10.53-4.03-14.29-19.75-8.42-35.13m71.43 168.91c10.83-23.26 16.35-26.83 60.19-16.48 70.03 16.52 83.76-2.11 88.15.35 4.39 2.45-17.07 29.78-58.65 33.64-31.28 2.9-93.74-8.81-89.69-17.51m-105.09 11.29c17.94-13.21-.1-33.04-9.59-39.12-41.06-26.3-29.09-51.85-32.93-54.06-3.85-2.2-17.63 19.76-6.58 47.82 8.32 21.12 42.38 50.31 49.1 45.36" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1779.48 2350.43c78.05 51.24 143.67 46.46 143.67 46.46 122.5 72.35 276.05 6.8 276.05 6.8-191.59 104.82-283.2 122.39-338.77 76.75-55.57-45.63-80.95-130.01-80.95-130.01m-9.18-25.05c-11.22-20.18 2.09-45.55 2.09-45.55-29.29 34.34-72.74 45.93-72.74 45.93 30.03 12.21 61.9 34.16 61.9 34.16 1.2-23.38 8.75-34.54 8.75-34.54M1896.15 1986c-.1-5.8 2.82-10.55 6.53-10.61 3.7-.06 6.79 4.58 6.89 10.38.1 5.79-2.83 10.54-6.54 10.61-3.7.06-6.79-4.59-6.88-10.38" style="fill:#455d7a;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2258.52 2066.6c5.17 27.41 32.04 50.17 61.83 37.83 36.62-15.16 56.69-59.89 50.96-97.06-5.73-37.18-51.04-85.24-75.65-88.73-38.64-5.49-46.12 100.34-37.14 147.96" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2277.33 1949.55s12.06 17.46 12.95 27.65c.88 10.19-11.87 43.5-11.87 43.5 4.37 38.98 32.42 67.91 55.93 49.66-14.63 3.32-24.91-1.22-34.01-12.59-9.1-11.38-14.91-34.83-14.85-37.01 5.82-11.05 10.31-37.39 10.31-37.39 18.16-2.83 22.26-1.96 22.26-1.96-13.22 8.62-22.13-9.1-22.13-9.1-5.52-13.07-18.59-22.76-18.59-22.76" style="fill:#f0a17d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2297.52 2052.92c-5.09-14.33 27.55-6.32 36.16-32.52 8.62-26.2-28.13-68.81-35.72-64.35-7.59 4.46-8.54 9.87-8.54 9.87l4.95 19.04-10.89 37s7.94 24.22 14.04 30.96" style="fill:#f0a17d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2055.3 1763.04c83.19 27.2 139.08 117.65 139.08 117.65s-8.7-34.57-65.2-83.11c-56.51-48.54-73.88-34.54-73.88-34.54" style="fill:#f08d6c;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1200.12 477.77h593.83v29.6h-593.83v-29.6" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m1168.47 1096.55-557.583-19.46c-8.533-1.42-13.733-10.18-10.882-18.35l200.517-579.62 589.228-1.61-221.28 619.04" style="fill:#455d7a;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m1160.62 1083.63 214.45-606.12 23.12.23-218.52 612.69c-1.88 5.26-7.67 8-12.93 6.12-5.26-1.87-8-7.66-6.12-12.92" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M980.229 777.11c9.62-25.22 10.788-48.19 2.608-51.31-8.18-3.12-22.61 14.79-32.23 40-9.621 25.22-10.789 48.19-2.609 51.31 8.18 3.12 22.61-14.79 32.231-40" style="fill:#f5f9fd;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M950.201 765.8c-9.214 25.22-10.331 48.19-2.497 51.31 1.359.55 2.907.41 4.573-.24-2.231 1.25-4.292 1.66-6.044.96-7.834-3.12-6.714-26.09 2.498-51.31 7.614-20.84 18.367-36.65 26.294-39.76-7.75 4.34-17.67 19.46-24.824 39.04" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m1298.63 1376.28-14.83 44.51c164.43-39.56 257.16-61.82 284.98-123.02 16.69 38.95 389.45 108.8 389.45 108.8-6.8-25.34 15.46-60.58 15.46-60.58l-464.26-104.47-210.8 134.76" style="fill:#dde9f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m1596.83 1304.96 385.13 80.05 122.08-455.43-341.78-77.47-165.43 452.85m-330.4 107.13 272.5-106.15 161.38-456.11-278.6 99.02-155.28 463.24" style="fill:#455d7a;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="m1538.93 1305.94 57.9-.98 165.43-452.85-61.95-2.28-161.38 456.11" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1964.47 651.15c-50.34 118.69-106.86 235.36-114.74 251.51-7.88 16.15-18.93 31.5-46.53 34.53-25.55 2.81-50.1 4.81-62.14 4.09-9.66-.82-55.88-13.44-73.82-35.51-6.06-7.44 1.06-26.63 5.87-35.29 8.75-15.73 15.51-16.35 19.94-19.41-10.82-20.18-7.35-52.25-7.35-52.25-12.66-10.4-21.31-33.46-22.34-42.6-.76-6.86 1.65-11.34 1.65-11.34-12.64-10.24-19.76-39.57-19.76-39.57.2-15.03 44.71-72.67 53.1-77.88 8.39-5.22 39.8 3.23 43.37 5.72 12.7-23.86 81.05-75.01 103.31-81.94 47.82-14.91 108.34-26.78 119.44 99.94" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1741.72 633.15c-.22 1.12-42.82 62.74-42.82 62.74l-23.77-5.21s17.59-.82 17.94-1.65c.35-.82 40.8-59.21 40.8-59.21l7.85 3.33m4.64 186.85-60.66-21.18s-.32 3.03-.46 7.78l61.12 13.4m-81.35-75.12 60.83 21.57-62.57-14.54c-.59-3.05 1.74-7.03 1.74-7.03m28.04 106.19c29.26 13.8 68.1 28.42 68.1 28.42l-34.58 6.12c5.49-5.25 11.36-6.67 11.36-6.67-28.52-11.88-56.89-19.65-56.89-19.65 2.39-4.29 12.01-8.22 12.01-8.22" style="fill:#f0a17d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M1869.55 544.67c191.89-67.16 462.79-110.85 606.55-123.59 96.25-11.48 315.5-6.96 312.05 123.82-2.04 77.43-98.8 167.3-204.25 150.99-307.74-47.59-631.07-22.07-644.59-24.87-34.52-53.88-69.76-126.35-69.76-126.35" style="fill:#ffc5a6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M589.238 394.55H3410.76v85.03H589.238v-85.03" style="fill:#eaf4fe;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M3252.72 588.13h-589.79c-9.54 0-17.27-7.73-17.27-17.27v-74.01c0-9.54 7.73-17.27 17.27-17.27h589.79c9.54 0 17.27 7.73 17.27 17.27v74.01c0 9.54-7.73 17.27-17.27 17.27" style="fill:#455d7a;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M3208.49 587.34h-142.81V480.37h142.81v106.97" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2979.99 599.91c-6.06 0-10.96 4.9-10.96 10.95v62.3c0 6.05 4.9 10.96 10.96 10.96h327.78v.32c0 6.33-5.12 11.45-11.45 11.45H2694.9c-6.33 0-11.46-5.12-11.46-11.45V598.8c0-6.33 5.13-11.46 11.46-11.46h601.42c6.33 0 11.45 5.13 11.45 11.46v1.11h-327.78" style="fill:#f95959;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2937.75 684.12h351.5v-84.21h-351.5c-6.05 0-10.95 4.9-10.95 10.95v62.3c0 6.05 4.9 10.96 10.95 10.96" style="fill:#f5f9fd;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2748.24 695.89h-35.56V587.34h35.56v108.55" style="fill:#e04545;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M2651.41 804.45v-12.17h380.74v-84.22h-380.74v-12.17h613.35c9.33 0 16.89 7.57 16.89 16.89v74.78c0 9.33-7.56 16.89-16.89 16.89h-613.35" style="fill:#233142;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
            <path d="M3057.61 792.28h-394.17v-84.22h394.17c6.4 0 11.59 5.19 11.59 11.6v61.02c0 6.41-5.19 11.6-11.59 11.6" style="fill:#f5f9fd;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:.1" transform="matrix(.16619 0 0 -.17001 -97.928 474.334)"/>
        </svg>
    `
}