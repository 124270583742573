import { runInAction } from 'mobx'
import { container, Symbols } from '../di'
import { BookmarkStore, ContentStore } from './content'
import { DeviceStore } from './device'

export class ManualBackup {
    protected contentStore: ContentStore
    protected bookmarkStore: BookmarkStore

    constructor(protected deviceStore: DeviceStore, protected db, protected deviceID: string) {
        this.contentStore = container.get<ContentStore>(Symbols.stores.content)
        this.bookmarkStore = container.get<BookmarkStore>(Symbols.stores.bookmark)
    }

    public doBackup = async () => {
        const books = this.getBooks()
        // console.log('NSX doBackup', books)
        let count = 0
        for(const book of books) {
            runInAction(() => {
                this.deviceStore.backupProgress = count++/books.length
                this.deviceStore.backupProgressMessage = book.Title    
            })
            await this.syncBook(book)
        }

        runInAction(() => {
            this.deviceStore.backupProgress = null
            this.deviceStore.backupProgressMessage = null
        })
    }
    
    syncBook = async (bookInfo) => {
        // console.log('NSX syncBook', bookInfo.Title)
        const book = this.getBook(bookInfo.ContentID)
        this.contentStore.pending = book
        await this.contentStore.create({
            query: { deviceID: this.deviceID }
        })

        const bookContent = this.getBookContent(bookInfo.ContentID)
        if (bookContent && bookContent.length) {
            const chunkSize = 100
            for(let i = 0; i < bookContent.length; i += chunkSize) {
                this.contentStore.pending = bookContent.slice(i, i + chunkSize)
                await this.contentStore.create({
                    query: { deviceID: this.deviceID }
                })
            }
        }

        const bookmarks = this.getBookBookMarks(bookInfo.ContentID)
        if (bookmarks && bookmarks.length) {
            for(const bookmark of bookmarks) {
            this.bookmarkStore.pending = bookmark
                await this.bookmarkStore.create({
                    query: { deviceID: this.deviceID }
                })
            }
        }
    }
    
    toData = (result) => {
        if (!result || !result.length) return {}
        const item = result[0]
        return item.values.map((row) => {
            const obj = {}
            item.columns.map((column, columnIndex) => obj[column] = row[columnIndex])
            return obj
        })
    }
    
    getBooks = () => {
        const query = 'select ContentID, Title from content where contentType=6 and IsDownloaded=\"true\" and ContentURL is null and ifnull(length(___UserID), 0)<>0'
        const result = this.db.exec(query)
        return this.toData(result)
    }
    
    getBook = (contentID: string) => {
        const query = `select * from content where ContentID="${contentID}"`
        const result = this.db.exec(query)
        return this.toData(result)
    }

    getBookContent = (bookID: string) => {
        const query = `select * from content where ContentType<>6 and BookID="${bookID}"`
        const result = this.db.exec(query)
        return this.toData(result)
    }

    getBookBookMarks = (volumeID: string) => {
        const query = `select * from Bookmark where VolumeID="${volumeID}"`
        const result = this.db.exec(query)
        return this.toData(result)
    }
}

