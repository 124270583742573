import { servicePaths } from '@konode-monorepo/kopanion-common'
import { computed, makeObservable, observable, observe } from 'mobx'
import { EntityStore } from './entity'

export interface Status {
    status?: string
    appVersion?: string
}

export class StatusStore extends EntityStore<Status> {

    mine(e: Status) { return true }
    idKey = null

    @computed get appVersion() { return this.entity?.appVersion }
    constructor() {
        super(servicePaths.status)
        makeObservable(this)
    }

    async serviceChanged() {        
        await super.serviceChanged()
        this.entity = await this.service.find() as Status
        this.markInitialized()
    }
}