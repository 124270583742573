import { servicePaths } from '@konode-monorepo/kopanion-common'
import { makeObservable } from 'mobx'
import { BaseStore } from './base'

export type GoogleDrivePickerCallback = (result: google.picker.ResponseObject) => void

export interface GoogleDrivePickerParams {
   appId: string
   developerKey: string
   token: string
}

export class GoogleDrive extends BaseStore {

    constructor() {
        super(servicePaths.googleDrive)
        makeObservable(this)
    }

    async getPickerParams(): Promise<GoogleDrivePickerParams> {
        return await this.service.find()
    }
}