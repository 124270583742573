import { deviceTypes, deviceNameTypes, deviceSchema, servicePaths } from '@konode-monorepo/kopanion-common'
import { computed, makeObservable, observable } from 'mobx'
import download from 'downloadjs'
import { MongoEntity, MongoEntityStore } from './mongo'
import { Busy } from './extensions'
import { config } from './config'


export interface Highlight extends MongoEntity {
    Text: string
    ContentID: string
}

export class HighlightStore extends MongoEntityStore<Highlight> {

    @computed get text() { return this.entity?.Text }

    constructor() {
        super(servicePaths.highlight)
        makeObservable(this)
    }
}