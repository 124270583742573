import { observe } from 'mobx'
import { EntityListStore } from '../list'

export const makeInfiniteScroll = async (gridElement: HTMLElement, store: EntityListStore) => {
    await store.initialized
    const scroller = gridElement?.shadowRoot.getElementById('table')
    if (!scroller) return
        
    const handleScroll = async () => {
        // console.log('NSX', scroller.scrollTop, scroller.clientHeight, scroller.scrollHeight, scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight)
        if (scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight)
            await store.next()
    }

    scroller.addEventListener('scroll', handleScroll)
    await handleScroll()
    return handleScroll
}