import { deviceTypes, servicePaths } from '@konode-monorepo/kopanion-common'
import { computed, makeObservable, observable, observe } from 'mobx'
import { Symbols } from '../di'
import { Device, DeviceStore } from './device'
import { MongoEntityListStore } from './mongo'

export class DeviceListStore extends MongoEntityListStore<Device, DeviceStore> {

    readonly addDevice = '**add-device**'
    deviceTypes = deviceTypes
    
    @observable selectedDevice: DeviceStore = null

    @computed get deviceSelectionItems() {
        return this.entities.map(e => ({ label: e.name, value: e._id, selected: false  }))
        // return [
        //     { label: 'Add Device', value: this.addDevice },
        //     { component: 'hr' },
        //     ...this.entities.map(e => ({ label: e.name, value: e._id  }))
        // ]
    }

    inQueue = (bookID: string) => this.stores.find(store => store.inQueue(bookID))
    @computed get firstDevice() { 
        return this.stores?.[0]
        // The following is needed if devices get disposed
        // but this is fixed by deviceStore never calling super.dispose
        const store = this.stores?.[0]
        if (store && !store.disposed)
            return store
        if (this.hasEntities)
            return this.newEntityStore(this.entities[0])
    }

    selectDevice(id: string) {
        this.selectedDevice = this.findStoreById(id)
    }

    constructor() {
        super(servicePaths.device, Symbols.stores.device)
        makeObservable(this)
    }

    init() {
        super.init()
        this.disposers.push(observe(this, 'entities', () => {
            if (!this.selectedDevice && this.hasEntities)
                this.selectedDevice = this.stores[0]
        }))
    }
}
