import { html, render } from 'lit'
import { customElement, query } from 'lit/decorators.js'
import { View } from '../view'
import { Symbols, inject } from '../../di'
import { DeviceListStore, DeviceStore, StatusStore } from '../../stores'
import { DeviceDownload } from './download'
import { DeviceWiredBackup } from './wired'

@customElement('kob-device-list')
export class DevicesView extends View {

    @inject(Symbols.stores.deviceList)
    devicesList: DeviceListStore

    @inject(Symbols.stores.status)
    statusStore: StatusStore

    @query('kob-device-create')
    deviceCreateDialog

    @query('kob-device-download')
    deviceDownloadDialog: DeviceDownload

    @query('kob-device-wired-backup')
    deviceWiredBackupDialog: DeviceWiredBackup

    @query('vaadin-grid')
    theGrid

    handleCreate = () => {
        this.deviceCreateDialog.deviceStore = this.devicesList.newStore()
        this.deviceCreateDialog.dialogOpened = true
    }

    render() {
        if (!this.devicesList.isInitialized)
            return html`
                <kob-loading></kob-loading>
            `

        if (this.devicesList.isNull)
            return html`<kob-device-null></kob-device-null>`

        return html`
            <kob-device-create></kob-device-create>
            <kob-device-download></kob-device-download>
            <kob-device-wired-backup></kob-device-wired-backup>
            <div class="flex flex-column">
                <vaadin-horizontal-layout theme="spacing padding"  style="justify-content:flex-end">
                    <layout-item  style="flex-grow: 1;align-self:center;text-align:start;">
                        <vaadin-vertical-layout theme="">
                            <span theme="badge">Latest Version: ${this.statusStore.appVersion}</span>
                            <span class="small">
                                Books are sent to the device the next time it syncs. If the epub already exists in the koblime folder, it will be skipped.
                            </span>
                            <span class="small">
                                Linked books are restored based on their original format irrespective of the preferred format for the device
                            </span>
                        </vaadin-vertical-layout>
                    </layout-item>
                    <layout-item>
                        <vaadin-button theme="primary" @click=${this.handleCreate}>Add Device</vaadin-button>
                    </layout-item>
                </vaadin-horizontal-layout>
                <vaadin-grid
                    class="stretch"
                    .items="${this.devicesList.stores}"
                    all-rows-visible>
                    <vaadin-grid-column header="Device Info" .renderer=${this.renderDeviceInfo} width="12em" flex-grow="0"></vaadin-grid-column>
                    <vaadin-grid-column header="Install" auto-width flex-grow="0" .renderer=${this.renderCommand}></vaadin-grid-column>
                    <vaadin-grid-column header="Book Queue" .renderer=${this.renderPending}></vaadin-grid-column>
                </vaadin-grid>
            </div>
        `
    }


    renderPending = (root, column, rowData) => {
        const store = rowData.item as DeviceStore
        
        const remove = async (id) => await store.removeGoogleDoc(id)

        const pickerCallback = async (data) => {
            if (data.action === google.picker.Action.PICKED)
                await store.addGoogleDoc(data.docs[0])
        }

        const template = html`
            <vaadin-vertical-layout>
                <layout-item>
                    ${!store.driveDocs || store.driveDocs.length < 3 
                        ? html`
                            <kob-drive-pick .pickerCallback=${pickerCallback}></kob-drive-pick>
                            ${this.renderKepubify(store)}
                        `
                        : ''
                    }
                </layout-item>
                ${store.driveDocs?.map(doc => html`
                    <layout-item>
                        <vaadin-button theme="icon small" @click=${() => remove(doc.id)}>
                            <vaadin-icon icon="vaadin:close-small"></vaadin-icon>
                            ${doc.name}
                        </vaadin-button>
                    </layout-item>
                `)}
            </vaadin-vertical-layout>
        `
        render(template, root)
    }

    renderDeviceInfo = (root, column, rowData) => {
        const store = rowData.item as DeviceStore
        store.beginTimer(this, this.theGrid)

        const handleClick = async () => {
            this.deviceCreateDialog.deviceStore = store
            this.deviceCreateDialog.dialogOpened = true
        }

        const versionTemplate = html`
            <span>${store.version}</span>
            ${store.isOutdated ?
                html`<vaadin-icon icon="vaadin:warning" style="color:var(--lumo-error-color);"></vaadin-icon>`:
                ''
            }
        `

        render(html`
            <vaadin-vertical-layout>
                <layout-item>
                    <vaadin-button theme='tertiary' @click=${handleClick}>${store.name}</vaadin-button>
                </layout-item>
                <layout-item>
                    <span theme="badge contrast">${store.typeLabel}</span>
                </layout-item>
                <layout-item>
                    <span class="small">${versionTemplate}</span>
                </layout-item>
                <layout-item>
                    <span class="small">${store.lastSyncDisplay}</span>
                </layout-item>
            </vaadin-vertical-layout>
        `, root)

    }

    renderCommand = (root, column, rowData) => {
        const store = rowData.item as DeviceStore

        const handleInstall = async () => {
            // render(downloadTemplate, root)
            // await store.getFile()
            this.deviceDownloadDialog.deviceStore = store
            this.deviceDownloadDialog.dialogOpened = true
            // render(installTemplate, root)
        }

        const handleBackup = async () => {
            this.deviceWiredBackupDialog.deviceStore = store
            this.deviceWiredBackupDialog.dialogOpened = true
            // await store.backup()
        }

        const downloadTemplate = html`
            <vaadin-button theme='tertiary' disabled>Generating installer...</vaadin-button>
        `
        const installTemplate = html`
            <vaadin-vertical-layout>
                <layout-item>
                    <vaadin-button theme="icon small" @click=${handleInstall}>
                        <vaadin-icon icon="lumo:download"></vaadin-icon>
                        Install
                    </vaadin-button>
                </layout-item>
                ${this.renderUninstall(store)}
                <layout-item>
                    <vaadin-button theme="icon small" @click=${handleBackup}>
                        <vaadin-icon icon="vaadin:spark-line"></vaadin-icon>
                        Wired Backup
                    </vaadin-button>
                </layout-item>
            </vaadin-vertical-layout>
        `
        render(installTemplate, root)
    }

    renderUninstall = (store: DeviceStore) => {
        const handleChecked = (e) => store.markUninstall(e.target.checked)
        return html`
            <layout-item>
                <vaadin-checkbox theme="small" ?checked=${store.markedUninstall} @checked-changed=${handleChecked} label="Uninstall on sync"></vaadin-checkbox>
            </layout-item>
        `
    }

    renderKepubify = (store: DeviceStore) => {
        const handleChecked = (e) => store.markKepubify(e.target.checked)
        return html`
            <layout-item>
                <vaadin-checkbox theme="small" ?checked=${store.markedKepubify} @checked-changed=${handleChecked} label="Prefer kepub"></vaadin-checkbox>
                <span theme="badge small error">KEPUB support is experimental!</span>
            </layout-item>
        `
    }
}