import { ReactiveController, ReactiveControllerHost } from 'lit'
import _dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { BaseStore } from '../base'
import { observe } from 'mobx'

export const dayjs = _dayjs.extend(relativeTime)

export class DateController<K extends BaseStore, P extends keyof K = keyof K> implements ReactiveController {

    value
    private date: Date
    private _timerID?: NodeJS.Timer
    private _disposer

    constructor(private host: ReactiveControllerHost, private store: K, private field: P, private timeout = 1000) {
        this.host.addController(this)
    }

    hostConnected() {
        const update = () => {
            this.value = this.date ?
                //@ts-ignore
                dayjs().to(this.date) :
                'Never'
            this.host.requestUpdate()
        }

        this._disposer = observe(this.store, this.field, (change) => {
            this.date = change.newValue as Date
            update()
        }, true)

        // Start a timer when the host is connected
        this._timerID = setInterval(() => {
            // Update the host with new value
            update()
        }, this.timeout)
    }

    hostDisconnected() {
        // Clear the timer when the host is disconnected
        console.log('NSX diz')
        clearInterval(this._timerID)
        this._disposer?.()
        this._timerID = undefined
    }
}