import { action, computed, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { BaseStore } from './base'
import createDebug from 'debug'
import { EntityStore } from './entity'
import { isBusy, wrapBusy } from './extensions'
import { container } from '../di'

const debug = createDebug('@kob:stores')

export interface PagedResult<E> {
    limit: number,
    skip: number,
    total: number,
    data: E[]
}

export type ResultType<E> = E[] | PagedResult<E>

export abstract class EntityListStore<E = any, S extends EntityStore<E, keyof E> = EntityStore<E>, K extends keyof E = keyof E> extends BaseStore {

    @observable filter = null
    @observable sort = null
    @observable total: number = NaN
    @computed get loaded() { return this.entities.length }
    @observable entities: E[] = []
    @computed get hasEntities() { return !!this.entities.length }
    @computed get hasMore() { return this.loaded < this.total }

    @computed get isNull() {
        const busy = isBusy(this) ? this.busy : false
        return !busy && this.isInitialized && (isNaN(this.total) || this.total === 0 || !this.hasEntities) && !this.filter
    }

    limit: number
    skip: number

    private _stores: S[] = []
    @computed get stores() {
        debug(`Generating stores for instance ${this.serviceName} ${this.instanceID}`)
        if (!this.initialized) return
        for (const store of this._stores)
            store.dispose()
        return this._stores = this.entities.map(this.newEntityStore)
    }

    abstract readonly idKey: K
    protected findIndex(entity: Partial<E>): number {
        return this.entities.findIndex(e => e[this.idKey] === entity[this.idKey])
    }

    protected findById = (id: E[K]) => this.findIndex({ [this.idKey]: id } as unknown as Partial<E>)

    protected findStoreById = (id: E[K]): S => {
        const index = this.findById(id)
        if (~index)
            return this.stores[index]
    }

    findStore(entity?: E) {
        if (entity) {
            const index = this.findIndex(entity)
            if (~index)
                return this.stores[index]
        }
    }

    constructor(serviceName?: string, public entityStoreSymbol?: symbol) {
        super(serviceName)
        makeObservable(this)
    }

    newStore(): S {
        if (this.entityStoreSymbol)
            return container.get(this.entityStoreSymbol)
    }

    newEntityStore = (entity: E): S => {
        const store = this.newStore()
        if (store)
            store.entity = entity
        return store   
    }

    async serviceChanged() {
        await super.serviceChanged()
        if (this.service) {
            this.service.on('created', this.onCreated)
            this.service.on('updated', this.onUpdated)
            this.service.on('patched', this.onUpdated)
            this.service.on('removed', this.onRemoved)
        }
        this.disposers.push(
            reaction(() => [this.filter, this.sort],
                async () => await this.fetch(),
                { fireImmediately: this.filter !== null || this.sort !== null }
            )
        )
    }

    reset() {
        runInAction(() => {
            this.skip = NaN
            this.limit = NaN
            this.total = NaN
            this.filter = null
            this.entities = []
        })
    }

    async cleanFetch() {
        await runInAction(async () => {
            this.reset()
            await this.fetch()
        })
    }

    protected remove(index) {
        debug(`Instance ID ${this.instanceID} with service name "${this.serviceName}" removing %o`, toJS(this.entities[index]))
        const entities = this.entities.slice()
        entities.splice(index, 1)
        runInAction(() => {
            this.entities = entities
            this.total -= 1
        })
    }

    protected replace(index, entity) {
        debug(`Instance ID ${this.instanceID} with service name "${this.serviceName}" replacing %o with %o`, toJS(this.entities[index]), entity)
        const entities = this.entities.slice()
        entities[index] = entity
        runInAction(() => this.entities = entities)
    }

    protected push(entity) {
        debug(`Instance ID ${this.instanceID} with service name "${this.serviceName}" adding %o`, entity)
        const entities = this.entities.slice()
        entities.push(entity)
        runInAction(() => {
            this.entities = entities
            this.total += 1
        })
    }

    @action.bound
    protected onCreated(entity) {
        const index = this.findIndex(entity)
        if (~index)
            this.replace(index, entity)
        else
            this.push(entity)
    }

    @action.bound
    protected onUpdated(entity) {
        const index = this.findIndex(entity)
        if (~index) {
            this.replace(index, entity)
            debug(`Instance ID ${this.instanceID} with service name "${this.serviceName}" updated %o`, entity)
        }
    }

    @action.bound
    protected onRemoved(entity) {
        const index = this.findIndex(entity)
        if (~index) {
            this.remove(index)
            debug(`Service name "${this.serviceName}" deleted %o`, entity)
        }
    }

    async next() {
        if (this.hasMore) {
            this.skip = this.entities.length
            this.inNextLoad = true
            await this.fetch()
        }
    }

    protected inNextLoad = false
    private get skipQ() { return isNaN(this.skip) ? undefined : { $skip: this.skip } }
    private get limitQ() { return isNaN(this.limit) ? undefined : { $limit: this.limit } }

    protected processResult(result: ResultType<E>) {
        runInAction(() => {
            if (Array.isArray(result)) {
                this.entities = result
                this.total = result.length
            }
            else {
                if (this.inNextLoad) {
                    for (const element of result.data)
                        this.onCreated(element)
                }
                else
                    this.entities = result.data
                // Has to come after the above
                this.total = result.total
                this.limit = result.limit
                this.skip = result.skip
                this.inNextLoad = false
            }
        })
    }

    async fetch() {
        this.disposeGuard()

        const busy = this.inNextLoad ? null : this
        await wrapBusy(busy, async () => {
            const query = {
                ...this.sort,
                ...this.filter,
                ...this.skipQ,
                ...this.limitQ
            }

            const result = await this.service.find({ query }) as ResultType<E>
            debug(`Fetch ${this.serviceName} ${this.instanceID} %o %o`, query, result)
            this.processResult(result)
            this.markInitialized()
        })
    }
}