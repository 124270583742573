import { Router } from '@vaadin/router'
import { computed, makeObservable, observable, observe } from 'mobx'
import { BaseStore } from './base'
import { config } from './config'
import { MongoEntity, MongoEntityStore } from './mongo'

declare const google: any

interface User extends MongoEntity {
    picture?: string
    name?: string
    googleId?: string
}

export type providerTypes = 'google'
export class MeStore extends MongoEntityStore<User> {
    @computed get isAuthenticated() { return !!(this.id) }

    @computed get profileImage() { return this.entity?.picture }
    @computed get fullName() { return this.entity?.name }
    
    private accessToken

    constructor() {
        super('api/users')
        makeObservable(this)
    }

    init() {
        super.init()
        this.disposers.push(
            observe(this, 'entity', 
                async () =>  {
                    await this.initialized
                    if (!this.isAuthenticated) {
                        google.accounts.id.cancel()
                        google.accounts.id.disableAutoSelect()
                        Router.go('/login')
                        // For some reason, the login button does not
                        // show without this
                        window.location.reload()
                    } 
                }
            )
        )
    }

    async serviceChanged() {        
        await super.serviceChanged()
        await this.login()
        this.markInitialized()
    }

    async getAccessToken() {
        if (this.accessToken)
            return this.accessToken

        const result = await this.verifyToken()
        this.accessToken = result.accessToken

        return this.accessToken
    }

    async verifyToken(accessToken?: string) {
        try {
            const authClient = BaseStore.authClient
            const result = accessToken ?
                await authClient.authenticate({ strategy: 'jwt', accessToken }) :
                await authClient.reAuthenticate()
            return result
        }
        catch { }
    }

    async startOauth(provider: providerTypes = 'google', redirect = '/', popUp = false) {
        this.storage.setItem('attemptingOauth', 'true')
        //const linkToken = this.isAuthenticated ? `&feathers_token=${await this.getAccessToken()}` : ''
        //const authURL = `${config.url}/api/oauth/${provider}?redirect=${encodeURIComponent(redirect)}${linkToken}`
        const authURL = `${config.url}/api/oauth/${provider}?redirect=${encodeURIComponent(redirect)}`
        window.location.href = authURL
    }



    async logout() {
        try {
            const authClient = BaseStore.authClient
            await authClient.logout()
            // this.storage.removeItem(config.storageKey)
        }
        catch { /* suppress error */ }
        this.entity = null
    }


    async login() {
        //const attemptingOauth = this.storage.getItem('attemptingOauth')
        try {
            // const accessToken = await BaseStore.authClient.authentication.getFromLocation(window.location)
            // if (accessToken)
            //     await BaseStore.authClient.authentication.setAccessToken(accessToken)
            const result = await BaseStore.authClient.authenticate()
            this.entity = result.user
        }
        catch(ex) {
            if (ex.code === 404)
                await this.logout()

            // if (attemptingOauth)
            //     this.errors = ex

            // We have an unauthenticated user
            // We can't set entity to {} or null to mark it initialized so we explicity initialize the store
            // this.initialized = true
        }
        finally {
            this.storage.removeItem('attemptingOauth')
            this.busy = false
        }
    }
}