import { html, render } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Dialog } from '@vaadin/vaadin-core'
import { View } from '../view'
import { DeviceListStore, DeviceStore } from '../../stores'
import { inject, Symbols } from '../../di'
import { toJS } from 'mobx'
import { validateAll } from '../../vaadin'

@customElement('kob-device-create')
export class DevicesCreate extends View {

    @inject(Symbols.stores.deviceList)
    devicesList: DeviceListStore

    @property({ type: Object })
    deviceStore: DeviceStore

    @property()
    dialogOpened

    formRoot

    render() {
        return html`
            <vaadin-dialog
                aria-label="Create new device"
                .opened="${this.dialogOpened}"
                @opened-changed="${(e: CustomEvent) => (this.dialogOpened = e.detail.value)}"
                .renderer="${this.renderContent}"
            ></vaadin-dialog>
        `
    }

    handleCancel = () => this.dialogOpened = false

    handleSave = async () => {
        validateAll(this.formRoot)
        if (await this.deviceStore.save())
            this.dialogOpened = false
    }
    handleDelete = async () => {
        await this.deviceStore.remove()
        this.dialogOpened = false
    }

    handleNameInput = (e) => this.deviceStore.pending.name = e.target.value
    handleTypeInput = (e) => this.deviceStore.pending.type = e.target.value

    renderContent = (root: HTMLElement, dialog?: Dialog) => {
        this.formRoot = root
        const disableDelete = this.deviceStore.isNew || !this.deviceStore.canDelete
        render(
            html`
                <vaadin-vertical-layout
                theme="spacing"
                style="width: 300px; max-width: 100%; align-items: stretch;"
                >
                    <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                        ${this.deviceStore.isNew ? 'Add a' : 'Edit'} Device
                    </h2>
                    <vaadin-vertical-layout style="align-items: stretch;">
                        <vaadin-text-field label="Name"
                            required 
                            value=${this.deviceStore.name}
                            @input=${this.handleNameInput}
                        ></vaadin-text-field>
                        <vaadin-select label="Device Type"
                            required
                            .items="${this.devicesList.deviceTypes}"
                            value=${this.deviceStore.type}
                            @change=${this.handleTypeInput}
                        ></vaadin-select>
                    </vaadin-vertical-layout>
                    <vaadin-horizontal-layout theme="spacing"  style="justify-content: space-between;">
                        <vaadin-button ?disabled=${disableDelete} theme="error" @click="${this.handleDelete}">Delete</vaadin-button>
                        <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                            <vaadin-button @click="${this.handleCancel}">Cancel</vaadin-button>
                            <vaadin-button theme="primary" @click="${this.handleSave}">Save</vaadin-button>
                        </vaadin-horizontal-layout>
                    </vaadin-horizontal-layout>
                </vaadin-vertical-layout>
            `,
            root
        )
    }
}