import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { View } from './view'
import { config } from '../stores'

@customElement('kob-google-account')
export class GoogleAccountView extends View {

    render() {
        return html`
            <div id="g_id_onload" 
                    data-client_id="432481399158-p69rhehvb3u1kfkench6tgg9v43q8vle.apps.googleusercontent.com"
                    data-login_uri="${config.url}/api/oauth/google"
                    data-cancel_on_tap_outside="false"
                    data-auto_prompt="false"
                    data-ux_mode="redirect"
                    data-prompt_parent_id="g_id_onload">
            </div>
        `
    }
}