
const debugConfig = {
    url: 'http://localhost:5080',
    storageKey: 'koblime'
}

const productionConfig = {
    ...debugConfig,
    url: 'https://api.kobli.me',
}

const Config = () => window.location.hostname === 'localhost' ? 
    debugConfig :
    productionConfig

export const config = Config()
