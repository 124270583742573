//import '@vaadin/vaadin-core'

// Manual import of the following because of
// https://github.com/vaadin/vaadin-core/issues/275
//import '@vaadin/vaadin-lumo-styles'

import '@vaadin/vaadin-lumo-styles/vaadin-iconset.js'
export * from './helpers'
export * from './validation'

import { applyTheme } from './helpers'
applyTheme(document)

import '@vaadin/accordion'
import '@vaadin/app-layout'
import '@vaadin/app-layout/vaadin-drawer-toggle'
import '@vaadin/avatar'
import '@vaadin/avatar-group'
import '@vaadin/button'
import '@vaadin/checkbox'
import '@vaadin/checkbox-group'
import '@vaadin/combo-box'
import '@vaadin/context-menu'
import '@vaadin/custom-field'
import '@vaadin/date-picker'
import '@vaadin/date-time-picker'
import '@vaadin/details'
import '@vaadin/dialog'
import '@vaadin/email-field'
import '@vaadin/form-layout'
import '@vaadin/form-layout/vaadin-form-item'
import '@vaadin/grid'
import '@vaadin/grid/vaadin-grid-column-group'
import '@vaadin/grid/vaadin-grid-column'
import '@vaadin/grid/vaadin-grid-filter'
import '@vaadin/grid/vaadin-grid-selection-column'
import '@vaadin/grid/vaadin-grid-sorter'
import '@vaadin/grid/vaadin-grid-tree-toggle'
import '@vaadin/horizontal-layout'
import '@vaadin/icon'
import '@vaadin/icons'
import '@vaadin/integer-field'
import '@vaadin/item'
import '@vaadin/list-box'
import '@vaadin/login/vaadin-login-overlay'
import '@vaadin/login/vaadin-login-form'
import '@vaadin/menu-bar'
import '@vaadin/message-input'
import '@vaadin/message-list'
import '@vaadin/notification'
import '@vaadin/number-field'
import '@vaadin/password-field'
import '@vaadin/progress-bar'
import '@vaadin/radio-group'
import '@vaadin/scroller'
import '@vaadin/select'
import '@vaadin/split-layout'
import '@vaadin/tabs'
import '@vaadin/text-area'
import '@vaadin/text-field'
import '@vaadin/time-picker'
import '@vaadin/upload'
import '@vaadin/vertical-layout'
import '@vaadin/virtual-list'

/*
import '@vaadin/router'
import "@vaadin/app-layout"
import '@vaadin/accordion'
import '@vaadin/button'
import '@vaadin/checkbox'
import '@vaadin/combo-box'
import '@vaadin/context-menu'
import '@vaadin/date-time-picker'
import '@vaadin/details'
import '@vaadin/form-layout'
import '@vaadin/grid'
import '@vaadin/icons'
import '@vaadin/item'
import '@vaadin/list-box'
import '@vaadin/menu-bar'
import '@vaadin/notification'
import '@vaadin/horizontal-layout'
import '@vaadin/vertical-layout'
import '@vaadin/scroller'
import '@vaadin/progress-bar'
import '@vaadin/radio-group'
import '@vaadin/select'
import '@vaadin/tabs'
import '@vaadin/text-field'
import '@vaadin/vaadin-themable-mixin'
import '@vaadin/upload'
*/