import { html, render } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Dialog } from '@vaadin/vaadin-core'
import { View } from '../view'
import { BookStore } from '../../stores'
import { validateAll } from '../../vaadin'
import { inject, Symbols } from '../../di'
import { GoogleBooks, GoogleBooksList } from '../../stores/googleBooks'
import { blankCover } from './blank'

@customElement('kob-book-match')
export class BookMatch extends View {

    @property({ type: Object })
    bookStore: BookStore

    @inject(Symbols.stores.googleBooksList)
    googleBooks: GoogleBooksList

    @property()
    dialogOpened

    formRoot

    render() {
        return html`
            <vaadin-dialog aria-label="Match Book" .opened="${this.dialogOpened}"
                @opened-changed="${this.handleOpenChanged}" .renderer="${this.renderContent}">
            </vaadin-dialog>
        `
    }

    handleDone = () => this.dialogOpened = false

    handleOpenChanged = async (e: CustomEvent) => {
        this.dialogOpened = e.detail.value
        if (this.dialogOpened) {
            const query = `${this.bookStore.title} ${this.bookStore.author}`
            this.googleBooks.setSearch(query)
        }
        else
            this.googleBooks.reset()
    }

    handleSearchChanged = (e) => this.googleBooks.setSearch(e.target.value)

    renderContent = (root: HTMLElement, dialog?: Dialog) => {
        this.formRoot = root
        render(
            html`
                <kob-busy .stores=${[this.googleBooks]}></kob-busy>
                <vaadin-vertical-layout theme="spacing" style="min-width: 600px; max-width: 100%; align-items: stretch;">
                    <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                        Match Book
                    </h2>
                    <vaadin-vertical-layout style="align-items: stretch;">
                        <layout-item>
                            <span class="small">The following data comes from Google Books. Select your book to update the book metadata.</span>
                        </layout-item>
                        <layout-item>
                            <span theme="badge warning" class="small">
                                Google limits the number of free searches per day. If search fails, try again later. <br/>
                                Changing the ISBN and Title while the book is on your device may result in duplicate books.
                            </span>
                        </layout-item>
                        <layout-item>
                            <vaadin-horizontal-layout theme="spacing" style="padding:10px 0 10px 0;">
                                    <layout-item style="display:flex; flex-grow: 1;">
                                        <vaadin-text-field
                                            placeholder="Search"
                                            clear-button-visible
                                            value=${this.googleBooks.filter?.query}
                                            @change=${this.handleSearchChanged}
                                            style="display:flex; flex-grow: 1;"
                                        ></vaadin-text-field>
                                    </layout-item>
                                    <layout-item style="align-self:center;text-align:end;">
                                        Showing: ${this.googleBooks.loaded} of ${this.googleBooks.total}
                                    </layout-item>
                            </vaadin-horizontal-layout>
                        </layout-item>

                        <layout-item>
                            <vaadin-grid theme="row-stripes"
                                .items=${this.googleBooks.stores}>
                                <vaadin-grid-column width="9em" flex-grow="0" .renderer=${this.renderCover}></vaadin-grid-column>
                                <vaadin-grid-column .renderer=${this.renderMetadata}></vaadin-grid-column>
                            </vaadin-grid>
                        </layout-item>

                    </vaadin-vertical-layout>

                    <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                        <vaadin-button theme="primary" @click="${this.handleDone}">Done</vaadin-button>
                    </vaadin-horizontal-layout>

                </vaadin-vertical-layout>
            `,
            root
        )
    }

    renderCover = (root, column, rowData) => {
        const store = rowData.item as GoogleBooks
        this.autorun(() => {
            const template = store.coverURL ? 
                html`<img src=${store.coverURL} />` :
                blankCover
            render(template, root)
        })
    }

    renderMetadata = (root, column, rowData) => {
        const store = rowData.item as GoogleBooks

        this.autorun(() => {
            render(html`
                <div style="word-wrap:break-word;">
                    <vaadin-horizontal-layout>
                        <layout-item><h5 style="white-space:normal;">${store.title}</h5></layout-item>
                        <layout-item style="flex-grow: 1;align-self:center;text-align:end;">${this.renderCommands(store)}</layout-item>
                    </vaadin-horizontal-layout>
                    <p>${store.author}</p>
                    <span theme="badge contrast">ISBN: ${store.ISBN}</span>
                </div>
            `, root)
        })
    }

    renderCommands = (store: GoogleBooks) => {

        const selectMatch = async () => {
            await this.bookStore.matchBook(store)
            this.dialogOpened = false
        }
        
        return html`
            <vaadin-button theme='icon small' @click=${selectMatch}>
                <vaadin-icon icon="vaadin:tools"></vaadin-icon>Select
            </vaadin-button>
        `
    }

    async connectedCallback() {
        await super.connectedCallback()
        this.reaction(
            () => [this.googleBooks.filter, this.googleBooks.stores],
            () => this.formRoot ? this.renderContent(this.formRoot) : undefined
        )

    }
}