import { Router } from '@vaadin/router'
import { html, render } from 'lit'
import { customElement } from 'lit/decorators.js'
import { inject, Symbols } from '../../di'
import { HighlightListStore } from '../../stores'
import { View } from '../view'

@customElement('kob-highlight-list')
export class HighlightsView extends View {

    @inject(Symbols.stores.highlightList)
    highlightList: HighlightListStore

    handleSearchChanged = (e) => {
        // this.highlightList.setSearch(e.target.value)
    }

    handleSelected = (e) => {
        if (e.detail.value !== undefined) {
            const store = this.highlightList.chapterStores[e.detail.value]
            document.getElementById(store.chapterSlug).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }
        
    }

    render() {
        return html`
            <div class="flex flex-column" style="align-items:stretch;height:100%">
                <kob-busy .stores=${[this.highlightList]}></kob-busy>
                ${this.renderResults()}
                ${this.renderNone()}
            </div>
        `
    }

    renderResults() {
        if (!this.highlightList.hasEntities) return
        return html`
            <vaadin-horizontal-layout theme="spacing padding">
                <layout-item>
                    <vaadin-text-field
                        placeholder="Search"
                        clear-button-visible
                        @change=${this.handleSearchChanged}
                    ></vaadin-text-field>
                </layout-item>
                <layout-item>
                    <vaadin-button theme="tertiary" @click=${() => Router.go('/')}>Return to books</vaadin-button>
                </layout-item>
            </vaadin-horizontal-layout>
            <hr />
            <vaadin-split-layout>
                <vaadin-list-box style="width: 300px;" @selected-changed=${this.handleSelected}>
                    ${this.highlightList.chapterStores.map(chapterHighlights => html`
                        <vaadin-item style="padding-left:${chapterHighlights.chapterDepth*10}px;">${chapterHighlights.chapter}</vaadin-item>
                    `)}
                </vaadin-list-box>
                <div style="width:fit-content">
                    ${this.highlightList.chapterStores.map(chapterHighlights => html`
                        <h3 id=${chapterHighlights.chapterSlug}>${chapterHighlights.chapter}</h3>
                        ${chapterHighlights.stores.map(store => html`
                            <p>${store.text}</p>
                            <hr />
                        `)}
                    `)}
                </div>
            </vaadin-split-layout>
        `
    }

    renderNone() {
        if (this.highlightList.hasEntities || this.highlightList.busy) return ''
        return html`
            <div class="flex flex-row" style="justify-content: center;align-items:center;height:100%">
                <vaadin-horizontal-layout theme="spacing padding" style="align-items:center;width:80%;">
                    <layout-item>
                        ${this.renderImage()}
                    </layout-item>
                    <layout-item>
                    <p>No highlights found</p>
                    <vaadin-button theme="tertiary" @click=${() => Router.go('/')}>Return to books</vaadin-button>
                    </layout-item>
                </vaadin-horizontal-layout>
            </div>
        `
    }

    async connectedCallback() {
        await super.connectedCallback()
        const params = new URLSearchParams(window.location.search)
        const bookID = params.get('bookID')
        if (bookID)
            this.highlightList.setBookId(bookID)
        else
            Router.go('/')
    }

    renderImage = () => html`
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400pt" height="300pt" viewBox="0 0 4000 3000" version="1.1">
            <g id="surface1">
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(91.796875%,95.701599%,99.609375%);fill-opacity:1;" d="M 2293.460938 2545.179688 C 2753.53125 2545.179688 2813.691406 2279.761719 2767.679688 2106.351563 C 3210.050781 1915.238281 3011.871094 1462.261719 2753.53125 1462.261719 C 3018.949219 1285.308594 2926.929688 970.339844 2721.671875 956.179688 C 2849.078125 598.75 2226.21875 305.011719 1964.339844 708.460938 C 1575.050781 591.671875 1106.730469 828.78125 1191.660156 1200.371094 C 748.109375 1450.460938 1066.621094 1849.179688 1330.859375 1833.851563 C 906.183594 2074.5 973.425781 2304.53125 1249.460938 2403.621094 C 1525.5 2502.710938 2293.460938 2545.179688 2293.460938 2545.179688 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(86.71875%,91.40625%,96.09375%);fill-opacity:1;" d="M 2230 2474.171875 C 2590.570313 2474.171875 2637.71875 2266.148438 2601.671875 2130.238281 C 2948.371094 1980.460938 2727.148438 1592.019531 2524.671875 1592.019531 C 2732.699219 1453.339844 2685.539063 1202.03125 2524.671875 1190.941406 C 2686.628906 834.558594 2180.210938 504.371094 1972.050781 1034.648438 C 1666.949219 943.121094 1394.058594 996.429688 1460.621094 1287.671875 C 1112.988281 1483.671875 1293.628906 1759.75 1500.730469 1747.730469 C 1167.890625 1936.339844 1195.429688 2285.558594 1411.769531 2363.21875 C 1628.121094 2440.890625 2230 2474.171875 2230 2474.171875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(79.296875%,85.546875%,91.014099%);fill-opacity:1;" d="M 2196.828125 2230.628906 C 2453.628906 2230.628906 2487.210938 2082.46875 2461.53125 1985.679688 C 2795.988281 1993.101563 2550.898438 1602.351563 2406.699219 1602.351563 C 2554.851563 1503.578125 2469.960938 1310.898438 2355.390625 1303 C 2470.738281 1049.191406 2161.371094 827.730469 2013.121094 1205.390625 C 1691.828125 993.339844 1642.660156 1214.140625 1690.070313 1421.558594 C 1300.78125 1395.019531 1592.109375 1759.238281 1739.609375 1750.679688 C 1293.699219 1837.390625 1380.269531 2180.210938 1534.351563 2235.519531 C 1688.429688 2290.828125 2196.828125 2230.628906 2196.828125 2230.628906 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(79.296875%,85.546875%,91.014099%);fill-opacity:1;" d="M 1536.441406 763.089844 L 1231.960938 875.210938 C 1214.480469 881.640625 1195.101563 872.691406 1188.671875 855.21875 L 1103.640625 624.289063 C 1097.199219 606.808594 1106.148438 587.429688 1123.621094 581 L 1428.109375 468.878906 C 1445.578125 462.449219 1464.96875 471.398438 1471.398438 488.871094 L 1556.429688 719.808594 C 1562.871094 737.28125 1553.921875 756.660156 1536.441406 763.089844 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(79.296875%,85.546875%,91.014099%);fill-opacity:1;" d="M 1414.851563 791.101563 L 1531.449219 839.109375 L 1502.429688 754.210938 Z M 1414.851563 791.101563 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(96.09375%,97.65625%,99.21875%);fill-opacity:1;" d="M 1256.640625 699.371094 C 1255.96875 691.738281 1249.238281 686.109375 1241.609375 686.78125 C 1233.980469 687.460938 1228.351563 694.191406 1229.019531 701.820313 C 1229.699219 709.449219 1236.429688 715.078125 1244.058594 714.410156 C 1251.691406 713.730469 1257.320313 707 1256.640625 699.371094 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(96.09375%,97.65625%,99.21875%);fill-opacity:1;" d="M 1345.878906 672.320313 C 1345.210938 664.691406 1338.46875 659.058594 1330.851563 659.730469 C 1323.21875 660.410156 1317.578125 667.140625 1318.261719 674.769531 C 1318.941406 682.398438 1325.671875 688.03125 1333.300781 687.359375 C 1340.921875 686.679688 1346.558594 679.949219 1345.878906 672.320313 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(96.09375%,97.65625%,99.21875%);fill-opacity:1;" d="M 1434.191406 641.289063 C 1433.511719 633.660156 1426.78125 628.03125 1419.148438 628.710938 C 1411.519531 629.378906 1405.890625 636.109375 1406.570313 643.738281 C 1407.238281 651.371094 1413.96875 657 1421.601563 656.328125 C 1429.230469 655.648438 1434.859375 648.921875 1434.191406 641.289063 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 1621.828125 2184.53125 C 1618.289063 2175.101563 1482.039063 2124.371094 1464.929688 2131.449219 C 1447.828125 2138.53125 1410.828125 2199.570313 1410.828125 2199.570313 C 1423.050781 2238.800781 1505.039063 2273.851563 1552.820313 2281.390625 C 1600.589844 2288.929688 1625.371094 2193.96875 1621.828125 2184.53125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 1462.761719 2173.761719 C 1576.011719 2171.398438 1688.191406 2174.800781 1694.828125 2191.609375 C 1701.460938 2208.421875 1612.578125 2470.300781 1599.011719 2506.871094 C 1585.441406 2543.441406 1556.539063 2662.589844 1556.539063 2662.589844 C 1433.859375 2674.679688 1338.898438 2593.28125 1349.449219 2510.109375 C 1356.039063 2458.191406 1383.699219 2392.199219 1392.570313 2362.949219 C 1420.289063 2271.53125 1462.761719 2173.761719 1462.761719 2173.761719 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 1669.011719 2238.980469 L 1632.039063 2376.519531 L 1544.75 2407.78125 L 1659.171875 2415.449219 L 1661.730469 2415.609375 C 1679.851563 2348.988281 1698.300781 2272.46875 1694.269531 2262.238281 C 1692.378906 2257.449219 1683.429688 2248.820313 1669.011719 2238.980469 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(97.65625%,34.910583%,34.910583%);fill-opacity:1;" d="M 1581.308594 1821.789063 C 1557.511719 1853.628906 1527.898438 1911.808594 1499.449219 1974.039063 C 1446.449219 2089.949219 1410.828125 2199.570313 1410.828125 2199.570313 C 1485.148438 2168.609375 1641.589844 2207.539063 1693.789063 2273.890625 C 1742.628906 2335.980469 1752.949219 2012.890625 1752.949219 2012.890625 L 1581.308594 1821.789063 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 1735.890625 1993.890625 L 1698.511719 1952.269531 L 1630.96875 1877.070313 L 1624.671875 1872.988281 L 1660.941406 2068.628906 L 1668.789063 2371.210938 C 1701.871094 2385.589844 1663.238281 2272.421875 1680.410156 2294.238281 C 1692.878906 2310.101563 1703.71875 2299.460938 1712.941406 2274.878906 L 1735.890625 1993.890625 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 2513.238281 2279.199219 C 2458.089844 2294.238281 2389.96875 2294.238281 2389.96875 2294.238281 L 2409.429688 2145.601563 L 2499.679688 2210.191406 L 2513.238281 2279.199219 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(97.65625%,34.910583%,34.910583%);fill-opacity:1;" d="M 1861.78125 1648.378906 L 1581.308594 1821.789063 L 1683.941406 2060.960938 L 1610.808594 2495.078125 C 1610.808594 2495.078125 2195.921875 2531.648438 2199.449219 2529.289063 C 2202.988281 2526.929688 2341.011719 2088.890625 2341.011719 2088.890625 L 2489.058594 1824.441406 L 2185.910156 1662.089844 L 1861.78125 1648.378906 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 1870.328125 1577.300781 C 1993.160156 1555.039063 2174.980469 1593.53125 2174.980469 1593.53125 L 1958.660156 1840.808594 L 1870.328125 1577.300781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 1870.628906 1400.648438 C 1906.019531 1501.511719 1918.398438 1700.578125 1916.628906 1803.210938 C 1991.828125 1894.339844 2108.621094 1834.171875 2150.199219 1776.671875 C 2148.429688 1582.019531 2178.519531 1477.621094 2178.519531 1477.621094 C 2178.519531 1477.621094 2018.378906 1355.53125 1870.628906 1400.648438 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 1870.628906 1400.648438 C 1891.648438 1460.570313 1904.558594 1555.148438 1911.199219 1643.578125 L 2158.859375 1592.238281 C 2167.210938 1516.960938 2178.519531 1477.621094 2178.519531 1477.621094 C 2178.519531 1477.621094 2018.378906 1355.53125 1870.628906 1400.648438 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 1972.441406 717.078125 C 2069.160156 755.550781 2146.039063 857.191406 2170.960938 908.230469 C 2187.808594 942.71875 2190.460938 1081.921875 2221.710938 1140.75 C 2252.960938 1199.570313 2283.699219 1227.390625 2291.648438 1293.238281 C 2299.589844 1359.089844 2306.078125 1540.289063 2277.71875 1569.828125 C 2249.371094 1599.371094 2156.710938 1613.300781 1989.570313 1484.890625 C 1822.429688 1356.480469 1734.160156 1233.640625 1717.730469 1202.269531 C 1701.289063 1170.910156 1573.191406 1040.539063 1623.75 958.78125 C 1674.300781 877.011719 1972.441406 717.078125 1972.441406 717.078125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2247.410156 1422.78125 C 2231.289063 1448.988281 2179.039063 1438.300781 2172.421875 1434.191406 C 2181.808594 1464.390625 2226.621094 1483.921875 2235.679688 1471.601563 C 2244.738281 1459.269531 2247.410156 1422.78125 2247.410156 1422.78125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(41.967773%,24.705505%,18.821716%);fill-opacity:1;" d="M 2224.5 1327.699219 C 2166.878906 1341.839844 2167.261719 1378.96875 2168.191406 1392.101563 C 2168.980469 1403.429688 2179.710938 1423.230469 2201.261719 1419.449219 C 2222.808594 1415.660156 2247.488281 1370.949219 2218.601563 1348.96875 C 2210.550781 1340.75 2224.5 1327.699219 2224.5 1327.699219 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(13.719177%,19.213867%,25.87738%);fill-opacity:1;" d="M 1862.949219 1361.671875 C 1862.949219 1361.671875 1815.488281 1265.878906 1813.96875 1259.621094 C 1778.839844 1143.960938 1762.101563 1011.339844 1762.101563 1011.339844 C 1715.210938 1010.320313 1666.449219 1011.871094 1666.449219 1011.871094 C 1666.449219 1011.871094 1774.140625 889.640625 1909.230469 834.039063 C 2044.320313 778.441406 2051.890625 780.820313 2051.890625 780.820313 C 2083.71875 794.988281 2118.511719 831.941406 2142.75 861.710938 C 2142.78125 832.191406 2120.910156 695.078125 2100.511719 674.179688 C 2080.109375 653.28125 2008.039063 683.398438 1995.738281 681.128906 C 1983.441406 678.859375 1897.691406 624.601563 1827.429688 639.449219 C 1757.171875 654.289063 1691.71875 782.679688 1663.328125 827.570313 C 1634.941406 872.460938 1568.769531 975.128906 1561.039063 1012.890625 C 1553.300781 1050.648438 1690.210938 1254.578125 1762.308594 1310.640625 C 1834.410156 1366.710938 1862.949219 1361.671875 1862.949219 1361.671875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,49.414063%,40.380859%);fill-opacity:1;" d="M 2169.699219 1399.191406 C 2173.410156 1410.050781 2183.769531 1422.519531 2201.261719 1419.449219 C 2213.070313 1417.371094 2225.808594 1402.988281 2230.160156 1386.808594 C 2216.289063 1384.96875 2191.878906 1384.679688 2169.699219 1399.191406 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,55.271912%,42.358398%);fill-opacity:1;" d="M 2202.601563 1218.480469 C 2194.121094 1260.050781 2133.070313 1273.5 2119.550781 1272.058594 C 2158.078125 1304.328125 2179.851563 1291.640625 2194.921875 1269.519531 C 2209.980469 1247.410156 2202.601563 1218.480469 2202.601563 1218.480469 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(13.719177%,19.213867%,25.87738%);fill-opacity:1;" d="M 2038.671875 1140.578125 C 2044 1159.148438 2037.21875 1177.378906 2023.53125 1181.308594 C 2009.828125 1185.238281 1994.410156 1173.378906 1989.078125 1154.820313 C 1983.75 1136.261719 1990.539063 1118.03125 2004.230469 1114.089844 C 2017.921875 1110.160156 2033.339844 1122.019531 2038.671875 1140.578125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(27.050781%,36.473083%,47.851563%);fill-opacity:1;" d="M 2025.488281 1122.289063 C 2029.75 1127.398438 2030.148438 1134.078125 2026.378906 1137.21875 C 2022.609375 1140.371094 2016.101563 1138.769531 2011.839844 1133.660156 C 2007.578125 1128.550781 2007.191406 1121.871094 2010.960938 1118.71875 C 2014.730469 1115.578125 2021.230469 1117.179688 2025.488281 1122.289063 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(13.719177%,19.213867%,25.87738%);fill-opacity:1;" d="M 2168.941406 1075.628906 C 2174.308594 1092.210938 2169.460938 1108.628906 2158.109375 1112.300781 C 2146.769531 1115.96875 2133.21875 1105.511719 2127.859375 1088.921875 C 2122.488281 1072.339844 2127.339844 1055.929688 2138.691406 1052.261719 C 2150.03125 1048.578125 2163.578125 1059.050781 2168.941406 1075.628906 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(13.719177%,19.213867%,25.87738%);fill-opacity:1;" d="M 1918.671875 939.351563 C 1937.378906 959.039063 1938.75 965.859375 1911.558594 1005.03125 C 1868.128906 1067.609375 1881.160156 1088.359375 1877.039063 1091.730469 C 1872.921875 1095.109375 1854.320313 1063.359375 1866.640625 1020.910156 C 1875.910156 988.96875 1911.660156 931.980469 1918.671875 939.351563 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(13.719177%,19.213867%,25.87738%);fill-opacity:1;" d="M 2069.570313 887.789063 C 2063.550781 910.589844 2091.589844 914.960938 2103.449219 913.671875 C 2154.769531 908.101563 2161.859375 937.121094 2166.5 936.390625 C 2171.128906 935.648438 2167.910156 908.398438 2140.171875 892.589844 C 2119.289063 880.699219 2071.820313 879.25 2069.570313 887.789063 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(27.050781%,36.473083%,47.851563%);fill-opacity:1;" d="M 2011.800781 707.171875 C 1913.421875 716.738281 1862.488281 764.25 1862.488281 764.25 C 1713.429688 785.921875 1630.339844 941.929688 1630.339844 941.929688 C 1718.730469 728.289063 1782.601563 652.96875 1858.71875 653.710938 C 1934.839844 654.449219 2011.800781 707.171875 2011.800781 707.171875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(27.050781%,36.473083%,47.851563%);fill-opacity:1;" d="M 2036 721.730469 C 2058.648438 730.921875 2064.519531 760.671875 2064.519531 760.671875 C 2065.878906 712.910156 2094 674.511719 2094 674.511719 C 2061.128906 684.378906 2020.28125 687.441406 2020.28125 687.441406 C 2034.808594 707.519531 2036 721.730469 2036 721.730469 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(27.050781%,36.473083%,47.851563%);fill-opacity:1;" d="M 2156.328125 1058.679688 C 2160.261719 1063.398438 2161 1069.25 2157.980469 1071.769531 C 2154.96875 1074.28125 2149.339844 1072.5 2145.410156 1067.78125 C 2141.480469 1063.070313 2140.738281 1057.210938 2143.761719 1054.699219 C 2146.769531 1052.191406 2152.398438 1053.96875 2156.328125 1058.679688 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2278.488281 1564.328125 C 2290.601563 1550.640625 2298.171875 1511.398438 2297.878906 1461.519531 C 2290.199219 1455.78125 2258.101563 1453.078125 2251.058594 1451.078125 C 2210.71875 1444.660156 2230.71875 1458.011719 2173.5 1452.558594 C 2165.808594 1451.820313 2126.53125 1442.289063 2112.160156 1443.410156 C 2077.570313 1446.128906 2082.671875 1488.828125 2100.949219 1505.941406 C 2130.179688 1533.28125 2231.011719 1544.269531 2231.011719 1544.269531 C 2212.730469 1564.328125 2205.671875 1574.988281 2197.679688 1593.53125 C 2255.921875 1606.441406 2263.351563 1581.449219 2278.488281 1564.328125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(97.65625%,34.910583%,34.910583%);fill-opacity:1;" d="M 1870.328125 1577.300781 C 1882.71875 1600.308594 1946.421875 1663.421875 1946.421875 1663.421875 L 1958.800781 1837.421875 L 2028.398438 1659.289063 L 2174.980469 1593.53125 L 2215.96875 1705.589844 C 2159.351563 1782.558594 1953.789063 1867.789063 1951.140625 1867.789063 C 1878.589844 1794.359375 1832.578125 1667.839844 1832.578125 1667.839844 L 1870.328125 1577.300781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(97.65625%,34.910583%,34.910583%);fill-opacity:1;" d="M 1936.101563 1674.039063 L 1821.078125 1835.058594 L 1813.121094 1677.578125 L 1870.328125 1577.300781 L 1936.101563 1674.039063 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(97.65625%,34.910583%,34.910583%);fill-opacity:1;" d="M 2040.199219 1678.171875 L 2093.878906 1861.601563 L 2215.96875 1714.140625 L 2174.980469 1593.53125 L 2040.199219 1678.171875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 1817.25 1828.570313 L 1918.578125 1698.558594 L 1945.820313 1850.019531 L 1939.921875 1872.988281 L 1652.980469 1802.320313 L 1813.121094 1698.558594 L 1817.25 1828.570313 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 2040.199219 1678.171875 L 1955.269531 1872.808594 L 2254.53125 1872.78125 L 2206.53125 1733.609375 L 2122.699219 1855.078125 L 2040.199219 1678.171875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 1958.660156 1840.808594 L 1929.898438 1912.921875 L 1942.199219 1958.039063 L 1854.941406 2510.109375 L 1844.289063 2509.460938 L 1936.101563 1960.25 L 1920.609375 1909.378906 L 1958.800781 1837.421875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 2061.871094 2559.710938 C 2061.871094 2559.710938 1712.261719 2649.609375 1556.539063 2662.589844 C 1449.78125 2646.070313 1402.140625 2623.558594 1412.128906 2558.128906 C 1422.109375 2492.691406 1460.101563 2429.609375 1565.390625 2411.519531 C 1602.730469 2405.101563 2093.078125 2438.621094 2106.738281 2440.710938 C 2143.851563 2485.96875 2061.871094 2559.710938 2061.871094 2559.710938 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,49.414063%,40.380859%);fill-opacity:1;" d="M 2361.878906 2534.558594 L 2547.378906 2413.351563 L 2564.191406 2441.660156 L 2372.5 2567.589844 L 2361.878906 2534.558594 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 2558.039063 2431.300781 L 2390.339844 2542.078125 C 2390.339844 2542.078125 2391.941406 2547.929688 2393.691406 2553.671875 L 2564.191406 2441.660156 L 2558.039063 2431.300781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 2106.738281 2440.710938 C 2220.570313 2448.851563 2328.828125 2466.25 2344.621094 2468.21875 C 2360.398438 2470.191406 2376.421875 2475.039063 2386.871094 2497.289063 C 2396.550781 2517.878906 2405.261719 2537.871094 2408.109375 2548.160156 C 2410.191406 2556.480469 2412.871094 2598.808594 2399.53125 2620.160156 C 2395.039063 2627.359375 2376.921875 2626.898438 2368.289063 2625.359375 C 2352.609375 2622.539063 2350.160156 2617.058594 2346.320313 2614.21875 C 2332.519531 2629.070313 2304.671875 2635.359375 2304.671875 2635.359375 C 2299.578125 2648.941406 2282.75 2662.800781 2275.390625 2666.269531 C 2269.871094 2668.878906 2265.421875 2668.148438 2265.421875 2668.148438 C 2260.480469 2681.671875 2237.949219 2696.03125 2237.949219 2696.03125 C 2225.308594 2700.179688 2164.269531 2679.421875 2157.5 2673.890625 C 2150.730469 2668.359375 2148.800781 2639.640625 2149.859375 2635.929688 C 2126.238281 2632.140625 2015.121094 2571.21875 2014.601563 2550.589844 C 2014.089844 2529.949219 2003.800781 2486.328125 2106.738281 2440.710938 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2149.859375 2635.929688 C 2150.871094 2635.789063 2214.679688 2653.800781 2214.679688 2653.800781 L 2217.140625 2675.210938 C 2217.140625 2675.210938 2211.398438 2660.71875 2210.609375 2660.660156 C 2209.820313 2660.601563 2149.320313 2643.460938 2149.320313 2643.460938 L 2149.859375 2635.929688 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2305.011719 2578.488281 L 2304.671875 2635.359375 C 2304.671875 2635.359375 2307.300781 2634.761719 2311.320313 2633.511719 L 2305.011719 2578.488281 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2265.421875 2668.148438 L 2266.058594 2611.019531 L 2271.808594 2667.589844 C 2269.429688 2668.949219 2265.421875 2668.148438 2265.421875 2668.148438 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 1826.441406 2088.890625 C 1952.910156 2066.859375 2059.078125 2114.050781 2059.078125 2114.050781 C 1929.828125 1997.738281 1826.441406 2088.890625 1826.441406 2088.890625 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2205.351563 2563.5 C 2317.859375 2553.03125 2375.21875 2477.378906 2404.710938 2431.371094 C 2434.210938 2385.371094 2490.828125 2042.089844 2490.828125 2042.089844 L 2297.371094 2051.53125 L 2205.351563 2563.5 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2205.941406 2560.550781 C 2102.71875 2557.011719 2035.429688 2463.910156 2080.898438 2453.351563 C 2126.371094 2442.800781 2149.320313 2478.558594 2180.578125 2502.148438 C 2211.839844 2525.75 2205.941406 2560.550781 2205.941406 2560.550781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2450.078125 2263.300781 C 2456.328125 2232.828125 2462.351563 2201.589844 2467.769531 2172.53125 L 2318.75 2119.5 L 2286.011719 2401.738281 L 2337.949219 2437.128906 L 2376.699219 2245.578125 C 2376.699219 2245.578125 2412.671875 2247.800781 2450.078125 2263.300781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(97.65625%,34.910583%,34.910583%);fill-opacity:1;" d="M 2489.058594 1824.441406 C 2569.871094 1871.039063 2515.601563 2274.480469 2513.238281 2279.199219 C 2440.101563 2207.238281 2264.328125 2161.230469 2264.328125 2161.230469 C 2328.039063 1924.121094 2408.25 1810.878906 2489.058594 1824.441406 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(87.889099%,27.050781%,27.050781%);fill-opacity:1;" d="M 2357.160156 1948.859375 C 2378.980469 1899.910156 2433.839844 1849.179688 2433.839844 1849.179688 C 2433.839844 1849.179688 2364.828125 1899.320313 2358.929688 1913.46875 C 2353.03125 1927.628906 2357.160156 1948.859375 2357.160156 1948.859375 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 2252.539063 1841.550781 C 2252.539063 1841.550781 2099.179688 2233.191406 2062.609375 2349.980469 C 2026.039063 2466.761719 2135.75 2569.390625 2205.351563 2563.5 C 2274.949219 2557.601563 2337.949219 2491.539063 2345.730469 2405.421875 C 2349.519531 2363.488281 2382.300781 1816.480469 2381.121094 1801.140625 C 2336.289063 1756.320313 2252.539063 1841.550781 2252.539063 1841.550781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 2381.121094 1801.140625 C 2381.121094 1674.039063 2370.949219 1552.378906 2370.058594 1534.691406 C 2369.179688 1517 2351.539063 1498.96875 2328.921875 1485.589844 C 2307.980469 1473.199219 2279.078125 1453.589844 2268.460938 1449.609375 C 2256.960938 1445.628906 2212.140625 1447.988281 2173.210938 1447.988281 C 2134.28125 1447.988281 2117.171875 1435.890625 2098.890625 1436.480469 C 2080.601563 1437.070313 2102.371094 1485.820313 2136.488281 1500.179688 C 2153.300781 1507.261719 2211.25 1507.410156 2233.660156 1500.328125 C 2256.671875 1514.488281 2274.359375 1527.460938 2274.359375 1527.460938 C 2244.871094 1526.671875 2210.609375 1528.71875 2206.53125 1532.179688 C 2189.871094 1546.339844 2191.199219 1565.800781 2191.199219 1565.800781 C 2176.519531 1570.371094 2165.238281 1586.449219 2159.710938 1592.640625 C 2155.289063 1597.589844 2153.738281 1606.800781 2153.738281 1606.800781 C 2139.140625 1611.21875 2122.328125 1635.109375 2122.328125 1635.109375 C 2117.03125 1648.820313 2134.28125 1718.269531 2139.589844 1726.238281 C 2144.890625 1734.199219 2174.980469 1738.621094 2178.960938 1737.738281 C 2181.171875 1764.28125 2236.910156 1892.570313 2258.578125 1894.78125 C 2280.261719 1896.988281 2325.378906 1911.878906 2381.121094 1801.140625 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2178.960938 1737.738281 C 2179.179688 1736.628906 2165.019531 1664.300781 2165.019531 1664.300781 L 2142.679688 1659.878906 C 2142.679688 1659.878906 2157.5 1667.398438 2157.5 1668.289063 C 2157.5 1669.171875 2171 1737.738281 2171 1737.738281 L 2178.960938 1737.738281 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2274.359375 1527.460938 L 2318.300781 1538.230469 L 2256.601563 1515.101563 Z M 2274.359375 1527.460938 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2191.199219 1565.800781 L 2251.058594 1569.929688 L 2191.488281 1558.140625 Z M 2191.199219 1565.800781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2153.738281 1606.800781 L 2213.910156 1610.628906 L 2155.660156 1600.371094 C 2154.050781 1602.921875 2153.738281 1606.800781 2153.738281 1606.800781 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2030.261719 2570.160156 L 2001.128906 2541.300781 L 2011.941406 2572.210938 Z M 2030.261719 2570.160156 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 2346.320313 2614.21875 C 2349.488281 2585.761719 2350.601563 2549.050781 2350.601563 2549.050781 L 2365.640625 2576.25 C 2359.671875 2573.160156 2356.789063 2568.648438 2356.789063 2568.648438 C 2355.019531 2595.941406 2356.648438 2621.929688 2356.648438 2621.929688 C 2352.371094 2621.148438 2346.320313 2614.21875 2346.320313 2614.21875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,77.34375%,65.039063%);fill-opacity:1;" d="M 1795.570313 1303.410156 C 1768.539063 1291.511719 1732.578125 1301.328125 1725.511719 1334.730469 C 1716.808594 1375.78125 1743.851563 1420.089844 1779.730469 1437.351563 C 1815.621094 1454.601563 1884.710938 1443.820313 1902.5 1424.429688 C 1930.429688 1393.980469 1842.519531 1324.078125 1795.570313 1303.410156 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 1886.488281 1389.921875 C 1886.488281 1389.921875 1864.019531 1390 1854.601563 1384.671875 C 1845.171875 1379.339844 1823.71875 1348.261719 1823.71875 1348.261719 C 1787.078125 1328.730469 1745.019531 1335.890625 1746.878906 1367.339844 C 1752.75 1352.589844 1762.859375 1346.328125 1778.238281 1345.199219 C 1793.621094 1344.058594 1817.578125 1353.039063 1819.441406 1354.390625 C 1825.601563 1366.089844 1845.898438 1385.789063 1845.898438 1385.789063 C 1837.5 1403.328125 1834.289063 1406.390625 1834.289063 1406.390625 C 1834.679688 1389.691406 1855.488281 1392.519531 1855.488281 1392.519531 C 1870.199219 1395.53125 1886.488281 1389.921875 1886.488281 1389.921875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(94.139099%,63.084412%,49.021912%);fill-opacity:1;" d="M 1784.160156 1345.640625 C 1799.710938 1349.78125 1773.179688 1373.480469 1790.890625 1396.691406 C 1808.601563 1419.898438 1867.808594 1413.339844 1868.460938 1404.039063 C 1869.109375 1394.738281 1864.949219 1390.679688 1864.949219 1390.679688 L 1845.371094 1383.601563 L 1819.589844 1351.929688 C 1819.589844 1351.929688 1793.691406 1344.359375 1784.160156 1345.640625 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,49.414063%,40.380859%);fill-opacity:1;" d="M 2540.808594 785.230469 L 2448.460938 724.921875 L 2504.480469 583.03125 L 2637.390625 544.601563 L 2589.96875 434.339844 L 2480.878906 459.210938 L 2482.519531 566.289063 L 2366.949219 575.789063 L 2355.980469 477.261719 L 2409.910156 323.480469 L 2646.609375 303.421875 L 2744.421875 408.699219 L 2706.910156 652.109375 L 2577.101563 690.808594 Z M 2418.730469 905.78125 L 2411.800781 832.589844 L 2457.710938 798.320313 L 2564.21875 812.25 L 2553.371094 910.429688 L 2418.730469 905.78125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,49.414063%,40.380859%);fill-opacity:1;" d="M 2726.011719 1635 L 2671.441406 1579.371094 L 2731.648438 1489.941406 L 2829.210938 1483.589844 L 2813.21875 1400.289063 L 2734.148438 1400.898438 L 2719.03125 1475.058594 L 2637.820313 1464.058594 L 2645.210938 1394.398438 L 2705.769531 1296.449219 L 2872.179688 1318.539063 L 2923.691406 1406.039063 L 2860.871094 1568.339844 L 2765.390625 1575.339844 Z M 2623.460938 1699.671875 L 2629.789063 1648.101563 L 2666.679688 1631.429688 L 2738.078125 1657.199219 L 2715.671875 1723.320313 L 2623.460938 1699.671875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,49.414063%,40.380859%);fill-opacity:1;" d="M 1380.488281 1712.910156 L 1341.921875 1667.351563 L 1356.601563 1623.21875 L 1434.019531 1583.078125 L 1472.351563 1653.539063 Z M 1405.878906 1575.949219 L 1316.378906 1578.609375 L 1287.859375 1458.078125 L 1358.570313 1370.800781 L 1275.808594 1319.339844 L 1214.671875 1386.539063 L 1265.449219 1457.121094 L 1192.859375 1517.078125 L 1139.828125 1456.53125 L 1104.398438 1329.039063 L 1252.789063 1205.839844 L 1366.808594 1230.589844 L 1454.769531 1410.171875 L 1386.238281 1496.191406 L 1405.878906 1575.949219 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(13.719177%,19.213867%,25.87738%);fill-opacity:1;" d="M 2586.851563 2399.890625 C 2587.328125 2403.898438 2589.730469 2406.671875 2592.191406 2408.441406 L 2604.449219 2393.890625 L 2586.851563 2399.890625 "/>
            </g>
        </svg>
    `
}