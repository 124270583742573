import { servicePaths } from '@konode-monorepo/kopanion-common'
import { computed, makeObservable, observable, observe, runInAction } from 'mobx'
import { Symbols } from '../di'
import { Content } from './content'
import { Busy } from './extensions'
import { Highlight, HighlightStore } from './highlight'
import { PagedResult } from './list'
import { MongoEntityListStore } from './mongo'

export interface ChapterStores {
    contentID: string
    chapter: string
    chapterSlug: string
    chapterDepth: number
    stores: HighlightStore[]
}

interface highlighResultType extends PagedResult<Highlight> {
    chapters: Content[]
}

export class HighlightListStore extends MongoEntityListStore<Highlight, HighlightStore> {

    @observable chapters: Content[] = []

    @computed get chapterStores() {
        const minDepth = Math.min(...this.chapters.map(s => s.Depth))
        return this.stores.reduce((accum, value) => {
            const contentItem = this.chapters.find(c => this.isMatch(c.ContentID, value.entity.ContentID))
            const bookmarkItem = this.chapters.find(c => this.isMatch(c.ChapterIDBookmarked, contentItem?.ContentID))
            const chapterItem = bookmarkItem || contentItem || {
                ContentID: 'unknown',
                Title: 'Unknown',
                Depth: minDepth
            }

            if (accum.length) {
                const currentChapter = accum.find(c => c.contentID === chapterItem.ContentID)
                if (currentChapter) {
                    currentChapter.stores.push(value)
                    return accum
                }
            }

            const chapter = chapterItem.Title
            const chapterSlug = chapter.toLowerCase().replace(/[\W_]+/g, '-')
            const chapterDepth = chapterItem.Depth - minDepth - 1

            // Add a new chapter
            accum.push({
                contentID: chapterItem.ContentID,
                chapter,
                chapterSlug,
                chapterDepth,
                stores: [value]
            })
            return accum
        }, [ ] as ChapterStores[ ])
    }

    private isMatch(id1: string, id2: string) {
        if (!id1 || !id2) return false
        const id1C = id1.indexOf('!!') < 0
            ? id1
            : id1.substring(0, id1.indexOf('#'))
        const id2C = id2.indexOf('!!') < 0
            ? id2
            : id2.substring(0, id2.indexOf('#'))
        return id1C === id2C
    }

    constructor() {
        super(servicePaths.highlight, Symbols.stores.highlight)
        makeObservable(this)
    }

    protected processResult(result: highlighResultType) {
        if (Array.isArray(result)) throw Error('Unexpected array for highlight data')
        runInAction(() => {
            this.entities = result.data
            this.chapters = result.chapters
        })
    }

    setBookId = (bookID: string) => {
        runInAction(() => {
            this.filter = { bookID }
        })
    }
}