import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Book } from '../../stores'
import { View } from '../view'

@customElement('kob-book-notification')
export class BookNotificationView extends View {

    @property({ type: Object })
    entity: Book

    render() {
        return html`
            <vaadin-horizontal-layout theme="spacing" style="align-items: center">
            <vaadin-icon
                icon="vaadin:check-circle"
                style="color: var(--lumo-success-color)"
            ></vaadin-icon>
            <div>
                <b style="color: var(--lumo-success-text-color);">Book sync in progress</b>
                <div
                    style="font-size: var(--lumo-font-size-s); color: var(--lumo-secondary-text-color)"
                >
                    <b>${this.entity.Title}</a>
                </div>
            </div>
        </vaadin-horizontal-layout>
        `
    }
}