import { observable } from 'mobx'
import { EntityStore } from './entity'
import { Busy } from './extensions'
import { EntityListStore } from './list'

export interface MongoEntity {
    _id: string
}


export class MongoEntityStore<E extends MongoEntity = MongoEntity> extends EntityStore<E> implements Busy {
    @observable busy = false
    readonly idKey = '_id'
}

export class MongoEntityListStore<E extends MongoEntity = MongoEntity, S extends MongoEntityStore<E> = MongoEntityStore<E>> extends EntityListStore<E, S> implements Busy {
    @observable busy = false
    readonly idKey = '_id'
}