import { kobLinkField, servicePaths } from '@konode-monorepo/kopanion-common'
import { makeObservable } from 'mobx'
import { Symbols } from '../di'
import { MongoEntity, MongoEntityListStore, MongoEntityStore } from './mongo'

export interface Content extends MongoEntity {
    ContentID?: string
    MimeType?: string
    ChapterIDBookmarked?: string
    Title?: string
    Depth?: number
    DateLastRead?: Date
    Attribution?: string
    ___PercentRead?: number
    ReadStatus?: number
    ISBN?: string

    [kobLinkField]: any
    $unset: any
}

export class ContentStore extends MongoEntityStore<Content> {
    constructor() {
        super(servicePaths.content)
        makeObservable(this)
    }
}

export class BookmarkStore extends MongoEntityStore {
    constructor() {
        super(servicePaths.bookmark)
        makeObservable(this)
    }
}

export class ContentListStore extends MongoEntityListStore<Content, ContentStore> {
    constructor() {
        super(servicePaths.content, Symbols.stores.content)
        makeObservable(this)
    }
}