import 'reflect-metadata'

export * from './di'
export * from './symbols'

import { container } from './di'
import { Symbols } from './symbols'
import { 
    BookListStore, 
    BookStore, 
    BookmarkStore,
    ContentListStore, 
    ContentStore, 
    DeviceListStore,
    DeviceStore,
    fetchOnActivation,
    GoogleDrive,
    GoogleBooks,
    GoogleBooksList,
    MeStore, 
    StatusStore
} from '../stores/'
import { HighlightStore } from '../stores/highlight'
import { HighlightListStore } from '../stores/highlightList'

container.bind<StatusStore>(Symbols.stores.status).to(StatusStore).inSingletonScope()
container.bind<GoogleDrive>(Symbols.stores.googleDrive).to(GoogleDrive).inSingletonScope()
container.bind<GoogleBooksList>(Symbols.stores.googleBooksList).to(GoogleBooksList)
container.bind<GoogleBooks>(Symbols.stores.googleBooks).to(GoogleBooks)

// Bindings have to come before the rest
container.bind(Symbols.stores.me).to(MeStore).inSingletonScope()

container.bind<DeviceStore>(Symbols.stores.device).to(DeviceStore)
container.bind<DeviceListStore>(Symbols.stores.deviceList).to(DeviceListStore)
    .inSingletonScope()
    .onActivation(fetchOnActivation)

container.bind<BookStore>(Symbols.stores.book).to(BookStore)
container.bind<BookListStore>(Symbols.stores.bookList).to(BookListStore)
    .inSingletonScope()
    //.onActivation(fetchOnActivation)

container.bind<ContentStore>(Symbols.stores.content).to(ContentStore)
container.bind<ContentListStore>(Symbols.stores.contentList).to(ContentListStore)
container.bind<BookmarkStore>(Symbols.stores.bookmark).to(BookmarkStore)

container.bind<HighlightStore>(Symbols.stores.highlight).to(HighlightStore)
container.bind<HighlightListStore>(Symbols.stores.highlightList).to(HighlightListStore)