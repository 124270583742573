import { html, render } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Dialog } from '@vaadin/vaadin-core'
import { View } from '../view'
import { DeviceStore, DownloadResult } from '../../stores'

@customElement('kob-device-wired-backup')
export class DeviceWiredBackup extends View {

    @property({ type: Object })
    deviceStore: DeviceStore

    @property()
    dialogOpened: boolean

    result: DownloadResult

    formRoot

    render() {
        return html`
            <vaadin-dialog
                aria-label="Backup device"
                .opened="${this.dialogOpened}"
                @opened-changed="${(e: CustomEvent) => (this.dialogOpened = e.detail.value)}"
                .renderer="${this.renderContent}"
            ></vaadin-dialog>
        `
    }

    handleClose = () => {
        this.deviceStore = null
        this.dialogOpened = false
    }

    handleBackup = async () => {
        this.result = null
        this.renderContent(this.formRoot)

        this.autorun(() => {
            this.renderContent(this.formRoot)
        })

        const result = await this.deviceStore.backup()
        if (result.status !== 'error' && result.status !== 'cancel') {
            this.dialogOpened = false
            return
        }

        this.result = result
        this.renderContent(this.formRoot)
    }

    renderSuccess = (root: HTMLElement, dialog?: Dialog) => {
        render(html`
                <vaadin-vertical-layout
                theme="spacing"
                style="width: 400px; max-width: 100%; align-items: stretch;"
                >
                    <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                        Download Completed
                    </h2>
                    <vaadin-vertical-layout style="align-items: stretch;">
                        <p>
                            The Koblime app has been successfully copied to your device. Please safely unmount 
                            and unplug your kobo. 
                        </p>
                        <p>
                            Once unplugged, the Kobo will reboot in order to install the Koblime app.
                        </p>
                    </vaadin-vertical-layout>
                    <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                        <vaadin-button theme="primary" @click="${this.handleClose}">Done</vaadin-button>
                    </vaadin-horizontal-layout>
                </vaadin-vertical-layout>
        `, root)
    }

    isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    mobileTemplate = () => html`
        <vaadin-vertical-layout
        theme="spacing"
        style="width: 400px; max-width: 100%; align-items: stretch;"
        >
            <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                Backup ${this.deviceStore.name}
            </h2>
            <vaadin-vertical-layout style="align-items: stretch;">
                <p>
                    <span theme="badge error">
                        Koblime requires desktop computer to do a wired backup
                    </span>
                </p>
            </vaadin-vertical-layout>
            <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                <vaadin-button @click="${this.handleClose}">Cancel</vaadin-button>
            </vaadin-horizontal-layout>
        </vaadin-vertical-layout>

    `

    noDirectInstallTemplate = () => html`
        <vaadin-vertical-layout
        theme="spacing"
        style="width: 400px; max-width: 100%; align-items: stretch;"
        >
            <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                Backup ${this.deviceStore.name}
            </h2>
            <vaadin-vertical-layout style="align-items: stretch;">
            <p>Your browser does not support File System Access.</p>
            <p>Please use Chrome, Edge or Opera for wired backups.</p>
            </vaadin-vertical-layout>
            <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                <vaadin-button @click="${this.handleClose}">Close</vaadin-button>
            </vaadin-horizontal-layout>
        </vaadin-vertical-layout>
    `
    
    renderContent = (root: HTMLElement, dialog?: Dialog) => {
        this.formRoot = root

        const template = this.isMobile() ? 
            this.mobileTemplate() :
            !this.deviceStore.canDirectInstall ?
            this.noDirectInstallTemplate() :
            html`
                <vaadin-vertical-layout
                theme="spacing"
                style="width: 400px; max-width: 100%; align-items: stretch;"
                >
                    <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                        Backup ${this.deviceStore.name}
                    </h2>
                    <vaadin-vertical-layout style="align-items: stretch;">
                        ${this.result ?
                            html`
                                <layout-item>
                                    <span theme="badge error">${this.result.message}</span>
                                </layout-item>
                            ` : ''
                        }
                        ${this.deviceStore.backupHappening
                            ? html`
                                <layout-item>
                                    <div style="color: var(--lumo-secondary-text-color);">
                                        <div>${this.deviceStore.backupProgressMessage}</div>
                                        <vaadin-progress-bar value=${this.deviceStore.backupProgress}></vaadin-progress-bar>
                                    </div>
                                </layout-item>
                            `
                            : html`
                                <layout-item>
                                    <p>Make sure your Kobo is plugged in to this computer.</p>
                                    <p>Select your Kobo device and your book progress and highlights will be backed up.</p>
                                </layout-item>
                            `
                        }
                    </vaadin-vertical-layout>
                    ${this.deviceStore.backupHappening
                        ? ''
                        : html`
                            <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                                <vaadin-button @click="${this.handleClose}">Close</vaadin-button>
                                <vaadin-button theme="primary" @click="${this.handleBackup}">Select Kobo</vaadin-button>                    
                            </vaadin-horizontal-layout>
                        `
                    }
                </vaadin-vertical-layout>
            `
            
        render(template, root)
    }
}