import { html, render } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Dialog } from '@vaadin/vaadin-core'
import { View } from '../view'
import { BookStore } from '../../stores'
import { validateAll } from '../../vaadin'

@customElement('kob-book-edit')
export class BookEdit extends View {

    @property({ type: Object })
    bookStore: BookStore

    @property()
    dialogOpened: boolean

    @property()
    deleteOpened: boolean

    @property()
    deleteYES: string

    formRoot

    render() {
        return html`
            <vaadin-dialog
                aria-label="Delete Book"
                .opened="${this.deleteOpened}"
                .renderer="${this.renderDelete}"
            ></vaadin-dialog>
            <vaadin-dialog
                aria-label="Edit book"
                .opened="${this.dialogOpened}"
                @opened-changed="${(e: CustomEvent) => (this.dialogOpened = e.detail.value)}"
                .renderer="${this.renderContent}"
            ></vaadin-dialog>
        `
    }

    handleCancel = () => this.dialogOpened = false
    handleDeleteCancel = () => this.deleteOpened = false

    handleSave = async () => {
        validateAll(this.formRoot)
        if (await this.bookStore.save())
            this.dialogOpened = false
    }

    confirmDelete = () => {
        this.deleteYES = null
        this.deleteOpened = true
    }

    handleDelete = async () => {
        await this.bookStore.remove()
        this.deleteOpened = false
        this.dialogOpened = false
    }

    renderContent = (root: HTMLElement, dialog?: Dialog) => {
        this.formRoot = root
        render(
            html`
                <vaadin-vertical-layout
                theme="spacing"
                style="width: 400px; max-width: 100%; align-items: stretch;"
                >
                    <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                        ${this.bookStore.isNew ? 'Add a' : 'Edit'} Book
                    </h2>
                    <vaadin-vertical-layout style="align-items: stretch;">
                        <layout-item>
                            <span theme="badge warning" class="small">Changing the ISBN and Title while the book is on your device may result in duplicate books.</span>
                        </layout-item>
                        <vaadin-text-field label="Title"
                            required
                            value=${this.bookStore.title}
                            @input=${(e) => this.bookStore.pending.Title = e.target.value}
                        ></vaadin-text-field>
                        <vaadin-text-field label="Author"
                            value=${this.bookStore.author}
                            @input=${(e) => this.bookStore.pending.Attribution = e.target.value}
                        ></vaadin-text-field>
                        <vaadin-text-field label="ISBN"
                            value=${this.bookStore.ISBN}
                            @input=${(e) => this.bookStore.pending.ISBN = e.target.value}
                        ></vaadin-text-field>                        
                        <vaadin-checkbox
                            ?checked=${this.bookStore.completed}
                            @change="${(e) => this.bookStore.setCompleted(e.target.checked)}"
                            label="Completed"
                        ></vaadin-checkbox>
                    </vaadin-vertical-layout>
                    <vaadin-horizontal-layout theme="spacing"  style="justify-content: space-between;">
                        <vaadin-button theme="error" @click="${this.confirmDelete}">Delete</vaadin-button>
                        <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                            <vaadin-button @click="${this.handleCancel}">Cancel</vaadin-button>
                            <vaadin-button theme="primary" @click="${this.handleSave}">Save</vaadin-button>
                        </vaadin-horizontal-layout>
                    </vaadin-horizontal-layout>
                </vaadin-vertical-layout>
            `,
            root
        )
    }

    renderDelete = (root: HTMLElement, dialog?: Dialog) => {
        render(
            html`
                <vaadin-vertical-layout
                theme="spacing"
                style="width: 400px; max-width: 100%; align-items: stretch;"
                >
                    <h2 style="margin: var(--lumo-space-m) 0 0 0;">
                        Confirm Delete
                    </h2>
                    <vaadin-vertical-layout style="align-items: stretch;">
                        <layout-item>
                            <span theme="badge error">
                                Deleting the book is permanent and cannot be recovered.
                                Everything including reading progress and highlights will be lost.
                                Are you sure you want to do this?</span>
                        </layout-item>
                        <vaadin-text-field label="Type YES below"
                            required
                            value=${this.deleteYES}
                            @input=${(e) => {
                                this.deleteYES = e.target.value 
                                this.renderDelete(root, dialog)
                            }}
                        ></vaadin-text-field>
                    </vaadin-vertical-layout>
                    <vaadin-horizontal-layout theme="spacing" style="justify-content: flex-end">
                        <vaadin-button @click="${this.handleDeleteCancel}">Cancel</vaadin-button>
                        <vaadin-button theme="primary error" ?disabled=${this.deleteYES !== 'YES'} @click="${this.handleDelete}">Delete</vaadin-button>
                    </vaadin-horizontal-layout>
                </vaadin-vertical-layout>
            `,
            root
        )
    }
}