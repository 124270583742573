import createDebug from 'debug'
import { computed, makeObservable, runInAction } from 'mobx'
import { Symbols } from '../di'
import { wrapBusy } from './extensions'
import { showError } from './extensions/error'
import { IDEntity, IDEntityListStore, IDEntityStore } from './idEntity'

const debug = createDebug('@kob:google:books')

export interface industryIdentifiers {
    type: string
    identifier: string
}
export interface GoogleVolumeInfo extends IDEntity {
    volumeInfo: {
        imageLinks: {
            smallThumbnail: string
        }
        authors: string[]
        title: string
        industryIdentifiers: industryIdentifiers[]
    }
}

export class GoogleBooks extends IDEntityStore<GoogleVolumeInfo> {

    @computed get coverURL() { return this.entity?.volumeInfo?.imageLinks?.smallThumbnail }
    @computed get author() { return this.entity?.volumeInfo?.authors?.join(', ') }
    @computed get title() { return this.entity?.volumeInfo?.title }
    @computed get ISBN() {
        const isbnIdentifier =
            this.entity?.volumeInfo?.industryIdentifiers?.find(i => i.type === 'ISBN_13') ||
            this.entity?.volumeInfo?.industryIdentifiers?.find(i => i.type === 'ISBN_10')
        return isbnIdentifier?.identifier
    }

    constructor() {
        super(null)
        makeObservable(this)
    }
}

export class GoogleBooksList extends IDEntityListStore {

    constructor() {
        super(null, Symbols.stores.googleBooks)
        makeObservable(this)
    }

    private get skipGQ() { return isNaN(this.skip) ? undefined : { startIndex: this.skip.toString() } }
    private get limitGQ() { return isNaN(this.limit) ? undefined : { maxResults: this.limit.toString() } }

    setSearch = (value: string) => {
        runInAction(() => {
            this.reset()
            this.filter = value ?
                { query: value } :
                null
        })
    }

    async fetch() {
        this.disposeGuard()

        const q = this.filter?.query
        if (!q) return

        await wrapBusy(this, async () => {
            const searchParams = new URLSearchParams({
                q,
                filter: 'ebooks',
                printType: 'BOOKS',
                // projection: 'LITE', -- sadly, this omits ISBN :(
                ...this.limitGQ,
                ...this.skipGQ
            })
            const url = `https://www.googleapis.com/books/v1/volumes?${searchParams.toString()}`

            try {
                const result = await fetch(url)
                if (result.ok) {
                    const body = await result.json()

                    debug(`Fetch google books ${this.instanceID} %o %o`, q, body)
                    runInAction(() => {
                        this.total = body.totalItems
                        this.entities = body.items
                    })
                }
                else  {
                    const err = new Error(`Failed to load data from Google Books. Status: ${result.status} ${result.statusText}`)
                    showError(err)
                }
                    
            }
            catch (ex) {
                showError(ex)
            }
            finally {
                this.markInitialized()
            }
        })
    }
}